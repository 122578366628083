<template>
  <div>
    <b-row class="mb-36" >
      <b-col sm="12" md="4" lg="3" xl="3" align="left" >
        <GenericSelect
          name="unidade"
          labelKey="nome"
          route="unidade"
          :value="unidadeIndicadores"
          @input="v => $emit('change-unidade-indicadores', v)"
          disabledFirstOption
        />
      </b-col>
    </b-row>
    <br>
    <!-- <b-row class="mb-5">
      <b-col>
        <TempoProcessamento
          :unidadeIndicadores=unidadeIndicadores
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col>
        <RegistroNaoConformidade
          :unidadeIndicadores=unidadeIndicadores
        />
      </b-col>
    </b-row> -->
    <b-row class="mb-5">
      <b-col>
        <CicloIncompleto
          :unidadeIndicadores=unidadeIndicadores
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GenericSelect from '../../../components/Form/GenericSelect';
import TempoProcessamento from './TempoProcessamento';
import RegistroNaoConformidade from './RegistroNaoConformidade';
import CicloIncompleto from './CicloIncompleto';

export default {
  name: 'DashBoardGeral',
  components: {
    TempoProcessamento,
    RegistroNaoConformidade,
    CicloIncompleto,
    GenericSelect,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>

.unidade-div-align{
  left: -18px;
}

</style>
