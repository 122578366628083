<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Quantidade de materiais com ciclo incompleto</h4>
      </b-col>
      <b-col cols="2">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
      <b-col cols="2">
        <GenericSelect
            ref="tipoMaterial"
            name="tipoMaterial"
            labelKey="nome"
            v-model="id_tipo_material"
            route="tipoMaterial"
            :customFilters="filtersTipoMaterial"
            :disabled="loading"
            :firstOption="selectFirstOption"
            :state="null"
          ></GenericSelect>
      </b-col>
      <b-col cols="1">
        <div v-if="loadingExport || loading" class="mr-0">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            class="mr-0"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericSelect from '../../../components/Form/GenericSelect';
import GenericApi from '../../../services/genericRequest';
import unidadeService from '../../../services/unidade';

export default {
  name: 'CicloIncompleto',
  components: {
    ECharts,
    GenericSelect,
    RangeDatePicker,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateRange: {
        initDate: moment().subtract(7, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      },
      selectFirstOption: {
        value: null,
        label: 'todos',
      },
      filtersTipoMaterial: {
        onlyTipos: ['Rede', 'Hospital', 'Vídeo', 'Médico'],
      },
      loading: false,
      loadingExport: false,
      mountedFirstTime: false,
      id_tipo_material: 1,
      chartOptions: {
        color: ['#FF7D29', '#3989BF', '#21CCA9', '#FF3348'],
        legend: {
          left: '10%',
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: 'Expurgo',
            type: 'bar',
            stack: 'expurgo',
            data: [],
            label: {
              show: true,
              position: 'top',
              verticalAlign: 'middle',
            },
          },
          {
            name: 'Preparo',
            type: 'bar',
            stack: 'preparo',
            data: [],
            label: {
              show: true,
              position: 'top',
              verticalAlign: 'middle',
            },
          },
          {
            name: 'Esterilização',
            type: 'bar',
            stack: 'esterilizacao',
            data: [],
            label: {
              show: true,
              position: 'top',
              verticalAlign: 'middle',
            },
          },
          {
            name: 'Arsenal',
            type: 'bar',
            stack: 'arsenal',
            data: [],
            label: {
              show: true,
              position: 'top',
              verticalAlign: 'middle',
            },
          },
        ],
      },
      resultData: [],
    };
  },

  computed: {
    idsTipoMaterial() {
      if (this.id_tipo_material) return [this.id_tipo_material];
      if (!this.$refs.tipoMaterial) return [];
      const ids = this.$refs.tipoMaterial.options.map((item) => item.value);
      return ids;
    },
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    idsTipoMaterial() {
      if (this.mountedFirstTime) this.updateChart();
    },
    dateRange: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },

    async updateChart() {
      this.loading = true;

      this.resultData = await this.getChartData();
      // ordenar ciclos por data de encerramento forcado
      this.resultData.sort((a, b) => {
        if (a.loteSaida.createdAt < b.loteSaida.createdAt) return -1;
        return 1;
      });
      if (this.resultData) this.updateChartData();

      this.loading = false;
    },

    async getChartData() {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.clone().startOf('day').toISOString(),
        endDate: this.dateRange.endDate.clone().endOf('day').toISOString(),
        id_tipo_material: this.idsTipoMaterial,
      };
      try {
        const ciclosIncompletos = await GenericApi.getWithoutPagination(
          { filters },
          'home/ciclosIncompletos',
        );
        return ciclosIncompletos;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à não conformidades';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      }
    },

    updateChartData() {
      this.chartOptions.series = this.chartOptions.series.map((serie) => ({
        ...serie,
        data: [],
      }));
      const initDay = this.dateRange.initDate.clone().startOf('week');
      const endDay = this.dateRange.endDate.clone().startOf('week');
      const numberOfWeeks = endDay.diff(initDay, 'week') + 1;
      const weeks = Array.from({ length: numberOfWeeks }, (v, k) => `Semana ${k + 1}`);
      this.chartOptions.xAxis[0].data = weeks;

      this.resultData.forEach((ciclo) => {
        const serieIndex = this.chartOptions.series.findIndex(
          (serie) => serie.stack === ciclo.ultimoProcessoCiclo.subSetor.chave,
        );

        const initForceWeek = moment(ciclo.loteSaida.createdAt);
        const weeksAfterInitWeek = initForceWeek.diff(initDay, 'week');
        if (typeof this.chartOptions.series[serieIndex].data[weeksAfterInitWeek] === 'undefined') {
          this.chartOptions.series[serieIndex].data[weeksAfterInitWeek] = 1;
        } else {
          this.chartOptions.series[serieIndex].data[weeksAfterInitWeek]++;
        }
      });
    },

    async exportData() {
      this.loadingExport = true;
      const unidades = await unidadeService.getUnidades();
      const unidadesObj = unidades.reduce((acc, curr) => ({
        ...acc,
        [curr.id_unidade]: curr,
      }), {});
      const dataToExport = this.resultData.map((ciclo) => ({
        'id material': ciclo.material.shownId,
        'nome material': ciclo.material.descricao,
        'tipo material': ciclo.material.tipoMaterial.nome,
        'data início ciclo': moment(ciclo.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        'data encerramento': moment(ciclo.loteSaida.createdAt).format('DD/MM/YYYY HH:mm:ss'),
        justificativa: ciclo.loteSaida.justificativa,
        'responsável encerramento': ciclo.loteSaida.usuarioForced.nome,
        'coren responsável': ciclo.loteSaida.usuarioForced.coren,
        área: ciclo.ultimoProcessoCiclo.subSetor.nome,
        processo: ciclo.ultimoProcessoCiclo.tipoProcesso.nome,
        'unidade processamento': unidadesObj[ciclo.ultimoProcessoCiclo.id_unidade].nome,
        data_processamento: moment(ciclo.ultimoProcessoCiclo.createdAt).format('DD/MM/YYYY HH:mm:ss'),
      }));
      this.createXlsx(dataToExport, 'Ciclos Encerrados Forçadamente');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
</style>
