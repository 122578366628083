<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h4 class="row-dash-title">Reprocessamento de materiais</h4>
      </b-col>
      <b-col cols="2" >
        <div v-if="loadingExport || loading" class="float-right">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else class="float-right">
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row align="center" class="mt-2">
          <b-col v-for="(motivo, index) in chartOptions.series" :key="`motivo${index}`" cols="5">
            <div class="subtitle">
              <label class="label-square" :style="`background-color: ${chartOptions.color[index]};`"></label>
              <span>{{ motivo.name }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row class="info-box">
          <b-col cols="3">
          <div class="meta-box text-center">
            <div class="info-text">LIMITE</div>
            <span class="result-meta">
              {{ limite }}
            </span>
          </div>
          </b-col>

          <b-col cols="3.5">
            <div class="meta-box text-center">
              <div class="info-text">REPROCESSADOS</div>
              <span class="result-meta">
                {{ reprocessados }}
              </span>
            </div>
          </b-col>

          <b-col cols="4">
            <div class="meta-box text-center">
              <div class="info-text">{{ isCritical(acimaLimite) ? 'ACIMA DO LIMITE' : 'ABAIXO DO LIMITE' }}</div>
              <span
                :class="isCritical(acimaLimite) ? 'negative' : 'positive'"
                class="result-meta"
              >
                {{ Math.abs(acimaLimite) }}
                <arrow-down-icon v-if="!isCritical(acimaLimite)"/>
                <arrow-up-icon v-else/>
                <span class="small-rate"> {{ `${porcentagemAcimaLimite}%` }} </span>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <ECharts
              @click="chartClick"
              :option="chartOptions"
            />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import ECharts from 'vue-echarts';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'ReprocessamentoMensal',
  components: {
    ECharts,
  },
  inject: ['getExportedData', 'createXlsx'],

  computed: {
    ...mapState(['metas']),
    limite() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].limiteDeReprocessamentoDeMateriaisMensal) return 100;
      return +this.metas[this.unidadeIndicadores].limiteDeReprocessamentoDeMateriaisMensal;
    },
  },

  data() {
    return {
      loading: false,
      loadingExport: false,
      reprocessados: 110,
      acimaLimite: 0,
      totalValue: 0,
      porcentagemAcimaLimite: 0,
      materiaisReprocessedByMonth: {},
      selectedMonth: parseInt(moment().format('MM'), 10),
      selectedYear: parseInt(moment().format('yyyy'), 10),
      chartOptions: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 36.5,
          },
        ],
        color: [
          '#FF3348',
          '#73E5CF',
          '#3989BF',
          '#21CCA9',
          '#F8C51A',
          '#C2F2EA',
          '#B7D2E5',
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [{
          show: true,
          type: 'category',
          axisLabel: {
            interval: 0,
          },
          axisTick: { show: false },
          data: [],
        }],
        yAxis: [{
          show: false,
        }],
        series: [],
      },
    };
  },

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
    date: {
      deep: true,
      handler(d) {
        this.selectedMonth = d.monthIndex;
        this.selectedYear = d.year;
        this.updateChart();
      },
    },
  },

  mounted() {
    this.updateChart();
  },

  methods: {
    chartClick() {},

    async updateChart() {
      this.loading = true;

      await this.getMateriaisReprocessedByMonth();
      await this.setUpChartOptions();
      this.initReprocessamentoMensalData();

      this.loading = false;
    },

    isCritical(value) {
      return value > 0;
    },

    calculateQuantityAboveLimitAndReturn(limiteValue, reprocessadosValue) {
      return reprocessadosValue - limiteValue;
    },

    calculatePercentageAboveLimitAndReturn(limiteValue, reprocessadosValue) {
      return Math.round(((reprocessadosValue / limiteValue) - 1) * 100);
    },

    async setUpChartOptions() {
      this.chartOptions.series.forEach((series) => {
        series.data = new Array(Object.keys(this.materiaisReprocessedByMonth).length
          * this.materiaisReprocessedByMonth.tiposMaterial.length).fill(0);
      });
      await this.$nextTick();

      this.chartOptions.series = [];

      this.chartOptions.series = Object.keys(this.materiaisReprocessedByMonth)
        .filter((key) => key !== 'count' && key !== 'tiposMaterial')
        .map((key) => ({
          type: 'bar',
          name: key,
          stack: 'principal',
          data: [],
        }));

      this.chartOptions.xAxis[0].data = this.materiaisReprocessedByMonth.tiposMaterial
        .map((tm) => tm.nome);
    },

    async getMateriaisReprocessedByMonth() {
      const selectedDate = moment()
        .month(this.selectedMonth - 1)
        .year(this.selectedYear);
      try {
        this.materiaisReprocessedByMonth = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: selectedDate.clone().startOf('month').toISOString(),
              endDate: selectedDate.clone().endOf('month').toISOString(),
            },
          },
          'home/reprocessamentoMateriais',
        );

        return true;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos aos procedimentos de ciclo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      }
    },

    getDataValueByIdTipoMaterial(id_tipo_material, serieName) {
      if (this.materiaisReprocessedByMonth[serieName].count > 0) {
        return this.materiaisReprocessedByMonth[serieName].materialData
          .filter((elem) => elem.id_tipo_material === id_tipo_material)
          .length;
      }
      return 0;
    },

    initReprocessamentoMensalData() {
      const arrayTipos = this.chartOptions.xAxis[0].data
        .map((tipoMaterial) => this.materiaisReprocessedByMonth.tiposMaterial
          .find((tp) => tp.nome === tipoMaterial).id_tipo_material);

      const arrayReason = this.chartOptions.series.map((s) => s.name);

      this.reprocessados = this.materiaisReprocessedByMonth.count;
      this.acimaLimite = this.calculateQuantityAboveLimitAndReturn(this.limite, this.reprocessados);
      this.porcentagemAcimaLimite = this.calculatePercentageAboveLimitAndReturn(this.limite, this.reprocessados);
      for (let i = 0; i < arrayReason.length; i++) {
        for (let j = 0; j < arrayTipos.length; j++) {
          this.chartOptions.series[i].data.push(this.getDataValueByIdTipoMaterial(arrayTipos[j], arrayReason[i]));
        }
      }
    },

    async exportData() {
      this.loadingExport = true;
      const selectedDate = moment()
        .month(this.selectedMonth - 1)
        .year(this.selectedYear);

      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: selectedDate.clone().startOf('month').toISOString(),
        endDate: selectedDate.clone().endOf('month').toISOString(),
      };
      const exportedData = await this.getExportedData(
        'home/reprocessamentoMateriais/export',
        filters,
        'Erro ao recuperar dados de reprocessamento para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      const fileName = `Reprocessamento ${selectedDate.format('MMMM')}`;
      this.createXlsx(processosExported, fileName);
      this.loadingExport = false;
    },
  },
};

</script>

<style scoped>
.label-square {
  height: 35%;
  border-radius: 10%;
  width: 1.75rem;
  position: absolute;
  margin: 0;
  border-radius: 0.1rem;
}

.label-square.data-vencida {
  background-color: #FF3348;
}

.label-square.embalagem-violada {
  background-color: #73E6CF;
}

.label-square.reposicao-comodato {
  background-color: #3989BF;
}

.label-square.cancelamento-procedimento {
  background-color: #21CCA9;
}

.subtitle {
  display: flex;
  justify-content: "space-evenly";
  align-items: center;
}

.subtitle span{
  font-weight: 500;
  font-size: 10px;
  margin-left: 2rem;
}

.meta-box {
  max-width: 100%;
  width: 100%;
  border: 1px solid #CFD1E5;
  border-radius: 10px;
  padding: 12px 12px 0px 12px;
  font-weight: 500;
}

.meta-box span {
  font-weight: 500;
  font-size: 36px;
}

.info-box {
  margin-top: 1rem;
}

.info-text {
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #7e829f;
  font-weight: 500;
}

.result-meta.negative {
  color: #FF3348;
}

.result-meta.positive {
  color: #21CCA9;
}
.result-meta {
  position: inherit;
}

span.small-rate {
  font-size: 12px;
}

</style>
