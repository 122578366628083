<template>
  <b-row>
    <b-col cols="6">
      <h4>Rastrear Material</h4>
      <b-form-input
        @keyup.enter="goRastrearMaterial"
        placeholder="ID ou código do QR Code"
        v-model="materialRastreamento"
      />
      <Button
        @click="goRastrearMaterial"
        class="mt-2"
        text="IR PARA TELA DE RASTREABILIDADE"
        :loading="loading"
      />
    </b-col>
  </b-row>
</template>

<script>
import login from '../../../services/login';
import GenericApi from '../../../services/genericRequest';
import Button from '../../../components/Utils/Button';

export default {
  name: 'HomeCCI',
  components: {
    Button,
  },
  data() {
    return {
      user: login.getUser().nome,
      materialRastreamento: '',
      loading: false,
    };
  },

  methods: {
    async goRastrearMaterial() {
      this.loading = true;
      try {
        const { id_material } = await GenericApi.getWithoutPagination(
          {}, `material/${this.materialRastreamento}`,
        );
        if (!id_material) throw new Error('id_material não é válido');

        this.$router.push(`material/rastrear/${id_material}`);
      } catch (error) {
        let errorMessage = 'Não encontramos o material através do ID ou QrCode informado. Verifique e tente novamente.';
        if (error.response && error.response.data && error.response.data.error
          && error.response.data.error.errorMessage) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          icon: 'warning',
          title: 'Material não encontrado',
          text: errorMessage,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
