<template>

  <div class="mb-5 div-chart">
    <b-row>
      <b-row>
        <b-col cols="12">
          <h3>Ciclos de esterilização</h3>
        </b-col>
      </b-row>
      <b-col sm="12" md="12" lg="12" xl="12" class="pl-0 pr-0">
        <b-row class="mt-3">
          <!--card #01-->
          <b-col cols="3 px-3">
            <div
              class="card green"
            >
              <div class="card-content">
                <span class="text-card-light">Ciclos</span>
                <b-spinner
                  v-if="isBusy"
                  class="number-card"
                />
                <span v-else class="number-card text-card-light">
                  {{dataCards.ciclos}}
                </span>
              </div>
              <div >
                <BarChart2Icon class="dash-icon dash-color-icon-1"/>
              </div>
            </div>
          </b-col>

          <!--card #02-->
          <b-col cols="3 px-3">
            <div
              class="card ligth-blue"
            >
              <div class="card-content">
                <span class="text-card-dark">Pacotes</span>
                <b-spinner
                  v-if="isBusy"
                  class="number-card"
                />
                <span v-else class="number-card">
                  {{dataCards.pacotes}}
                </span>
              </div>
              <div >
                <BarChart2Icon class="dash-icon dash-color-icon-2"/>
              </div>
            </div>
          </b-col>

          <!--card #03-->
          <b-col cols="3 px-3">
            <div
              class="card ligth-grey"
            >
              <div class="card-content">
                <span class="text-card-dark">Meta de pacotes</span>
                <div>
                  <b-spinner
                    v-if="isBusy"
                    class="number-card"
                  />
                  <span v-else class="number-card">
                    {{dataCards.meta}}
                  </span>
                  <span>/Ciclo</span>
                </div>
              </div>
              <div >
                <BarChart2Icon class="dash-icon dash-color-icon-3"/>
              </div>
            </div>
          </b-col>

          <!--card #04-->
          <b-col cols="3 px-3">
            <div
              class="card ligth-green"
            >
              <div class="card-content">
                <span class="text-card-dark">Média de pacotes</span>
                <div>
                  <b-spinner
                    v-if="isBusy"
                    class="number-card"
                  />
                  <span v-else class="number-card">
                    {{(dataCards.media).toFixed(1)}}
                  </span>
                  <span>/Ciclo</span>
                </div>
              </div>
              <div >
                <BarChart2Icon class="dash-icon dash-color-icon-4"/>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="6"
          />
          <b-col cols="3">
            <b-form-select
              class="invision-input"
              v-if="tiposEsterilizacao && tiposEsterilizacao.length"
              name="tipoEsterilizacao"
              disabledFirstOption
              :disabled="isBusy.chart"
              :options="tiposEsterilizacao"
              v-model="id_tipo_esterilizacao"
              @change="fillTipoEsterilizacao"
            >
              <template slot="first">
                <option :value="null">
                  {{ '-- Tipos Esterilizacoes --' }}
                </option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="3">
            <RangeDatePicker
              :initDate="initDate"
              :endDate="endDate"
              :isBusy="isBusy.chart"
              @selected="rangeDateSelected"
              class="RangeDatePicker-in-graph pr-3"
            />
          </b-col>
        </b-row>
        <b-overlay
          :show="isBusy"
          rounded="sm"
          class="dashboard-overlay"
        >
          <b-row class="mt-6">
            <b-col cols="12 px-2">
            <!-- <b-col cols="12" class="pl-2 pr-0"> -->
              <!--grafico ciclos esterilização-->
              <div class="chart-box" id="chart">
                <!-- <span class="chart-titles pl-3">Ciclos de esterilização</span> -->
                <ECharts
                  :option="chart"
                  height="100px"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import { mapState } from 'vuex';
import moment from 'moment';
import GenericApi from '../../../services/genericRequest';
import RangeDatePicker from '../../../components/Form/RangeDatePicker';

export default {
  name: 'CiclosEsterilizacao',
  components: {
    ECharts,
    RangeDatePicker,
  },

  data() {
    return {
      isBusy: true,
      initDate: moment((`${moment().month() + 1}-01-${moment().year()}`)),
      endDate: moment(),
      ciclosEsterilizacao: {},
      tiposEsterilizacao: [],
      reCriarTiposEsterilizacao: true,
      id_tipo_esterilizacao: null,
      itensProcessados: [],
      dataCards: {
        ciclos: 0,
        pacotes: 0,
        media: 0,
        meta: 0,
      },
      chart: {
        height: '65%',
        width: '80%',
        color: ['#1B856F', '#B4D8F0', '#73E6CF'],
        legend: {
          show: true,
          bottom: '20%',
          right: '0',
          align: 'left',
          orient: 'vertical',
          padding: [24, 24],

          itemWidth: 20,
          itemHeight: 3,

          textStyle: {
            color: 'rgba(94,98,122,1)',
            fontWeight: 'normal',
            fontSize: 12,
          },
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: true },
            legend: true,
            data: [],
            axisLabel: {
              legend: true,
              rotate: 30,
            },
          },
        ],
        yAxis: [{
          type: 'value',
          show: true,
        }],
        series: [],
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          borderColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: '#ffffff',
          },
          formatter: (v) => {
            const idx = v.dataIndex;
            const tooltip = `
              <h6>${v.name}</h6>
              <span>${this.chart.series[0].data[idx]} ${this.chart.series[0].name}</span></br>
              <span>${this.chart.series[1].data[idx]} ${this.chart.series[1].name}</span></br>
              <span>${this.itensProcessados[idx]} itens</span></br>
              <span>${this.chart.series[2].data[idx]} média de pacotes/ciclo</span>
            `;
            return tooltip;
          },
        },
      },
    };
  },

  computed: {
    ...mapState(['metas', 'currentUnidade']),
  },

  mounted() {
    this.fillCiclosEsterilizacao();
  },

  methods: {
    rangeDateSelected(initDate, endDate) {
      this.initDate = initDate;
      this.endDate = endDate;
      this.reCriarTiposEsterilizacao = true;
      this.id_tipo_esterilizacao = null;
      this.clearChart();
      this.fillCiclosEsterilizacao();
    },

    clearChart() {
      this.chart.xAxis.data = [];
      this.chart.series = [];
      this.ciclosEsterilizacao = {};
      this.dataCards = {
        ciclos: 0,
        pacotes: 0,
        media: 0,
        meta: 0,
      };
    },

    fillTipoEsterilizacao() {
      this.reCriarTiposEsterilizacao = false;
      this.clearChart();
      this.fillCiclosEsterilizacao();
    },

    async fillCiclosEsterilizacao() {
      this.isBusy = true;
      try {
        // const result = {
        //   tipoEsterilizacao_1: {
        //     ciclos: 123,
        //     pacotes: 345,
        //     media: (345 / 123),
        //   }
        // };

        const filters = {
          initDate: this.initDate,
          endDate: this.endDate,
          id_unidade: this.currentUnidade.id_unidade || null,
          id_tipo_esterilizacao: this.id_tipo_esterilizacao || null,
        };

        const result = await GenericApi.getWithoutPagination(
          { filters },
          'home/getCiclosAndPacotesByEsterilizacao',
        );

        if (this.reCriarTiposEsterilizacao) {
          this.tiposEsterilizacao = Object.keys(result).map((te) => ({
            value: result[te].idTipoEsterilizacao,
            text: te,
          }));
        }

        this.itensProcessados = Object.keys(result).map((te) => (
          [result[te].itens]
        ));
        console.log('this.itensProcessados', this.itensProcessados);
        this.ciclosEsterilizacao = result;
        const series = [
          'ciclos',
          'pacotes',
          'media',
        ];

        this.chart.series = [];
        series.forEach((key) => {
          const currentSeries = {
            name: key,
            type: 'bar',
            data: [],
            label: { show: true, position: 'top', fontWeight: 700 },
          };
          this.chart.series.push(currentSeries);
        });

        this.chart.xAxis[0].data = Object.keys(result).map((nameSerie) => nameSerie);
        series.forEach((key, idx) => {
          const dataSerie = [];
          Object.keys(result).forEach((key2) => {
            let dataSerieKey = result[key2][key] || 0;
            this.dataCards[key] += dataSerieKey;
            if (key === 'media') dataSerieKey = dataSerieKey.toFixed(2);
            dataSerie.push(dataSerieKey);
          });
          this.chart.series[idx].data = dataSerie;
        });
        this.dataCards.media = this.dataCards.media
          && this.chart.xAxis[0].data.length
          ? this.dataCards.media / this.chart.xAxis[0].data.length : 0;
        this.dataCards.meta = this.metas['1'].numeroDePacotesPorCicloMensal || 0;
      } catch (e) {
        console.log('error in fillCiclosEsterilizacao:', e);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
#printSection table tbody tr{
  cursor: pointer  !important;
}

.icon-circle {
  border-radius: 50%;
  width: 60px !important;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-icon {
  width: 100%;
  height: 100%;
}
.dash-color-icon-1 {
  color:#1B856F
}
.dash-color-icon-2 {
  color:#B4D8F0;
}
.dash-color-icon-3 {
  color:#FFFFFF
}
.dash-color-icon-4 {
  color:#73E6CF
}

.card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 0 0 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.card-bar {
  background-color: #1B856F;
}

.number-card {
  font-size: 36px;
  line-height: 1;
  font-weight: 600;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-card-dark {
  font-size: 16px;
  color: #5e627a;
}

.text-card-light {
  color: #FFFFFF
}

.chart-box {
  background-color: #ffffff;
  border-radius: 10px;
  max-height: 465px;
}

#chart {
  padding-top: 20px;
  height: 463px;
}

.light-orange {
  background-color: rgba(255, 100, 0, 0.1);
}
.light-green {
  background-color: rgba(33, 204, 169, 0.1);
}

.green {
    background-color: #209F85
}

.ligth-blue {
    background-color: #D4EAFA

}

.ligth-gray {
    background-color: #F2F2F7
}

.ligth-green {
    background-color: #F4FBFA
}

.light-orange .number-card {
  color: rgba(255, 100, 0, 0.84);
}

.light-green .number-card {
  color: #209f85;
}

.light-orange .icon-circle {
  background-color: rgba(255, 108, 13, 0.3);
}

.light-green .icon-circle {
  background-color: rgba(33, 204, 169, 0.3);
}

#total-esterilizado-chart {
  position: absolute;
  z-index: 9;
  top: 52%;
  left: 24%;
  font-size: 32px;
  font-weight: 500;
  color: #35384d;
}

#filter-box {
  display: flex;
  width: 50%;
}

#filter-button {
  background: #F7F7FA;
  height: 28px;
  width: 28px;
  margin-top: 1px;
  margin-left: -30px;
  padding: 0;
}

#filter-input {
  background: #F7F7FA;
}

</style>
