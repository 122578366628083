<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h4 class="row-dash-title">Número de pacotes por ciclo</h4>
      </b-col>
      <b-col cols="2" >
        <div v-if="loadingExport || loading" class="float-right">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else class="float-right">
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col>
            <div class="div-informacao">
              <div>
                <p class="title-informacao">N° de pacotes</p>
                <p class="text-informacao">{{ informacoes.numberOfPacotes }}</p>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="div-informacao">
              <div>
                <p class="title-informacao">Meta</p>
                <p class="text-informacao">
                  {{ meta }}<span class="sub-text">/ciclo</span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="div-informacao">
              <div>
                <p class="title-informacao">Valor Alcançado</p>
                <p class="text-informacao">
                  {{informacoes.pacotesPorCiclo.toFixed() == informacoes.pacotesPorCiclo
                  ? informacoes.pacotesPorCiclo.toFixed()
                  : informacoes.pacotesPorCiclo.toFixed(1)}}
                  <span class="sub-text">/ciclo</span>
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="4">
            <div class="div-informacao">
              <div>
                <p class="title-informacao">N° de ciclos</p>
                <p class="text-informacao">{{informacoes.numberOfCiclos}}</p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt--1-8rem mb--2rem">
          <b-col>
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';

import { mapState } from 'vuex';
import ECharts from 'vue-echarts';
import GenericApi from '../../../services/genericRequest';

export default {
  components: {
    ECharts,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      deep: true,
      handler() {
        this.updateAll();
      },
    },
    date: {
      deep: true,
      handler(d) {
        this.selectedMonth = d.monthIndex;
        this.selectedYear = d.year;
        this.updateAll();
      },
    },
  },

  computed: {
    ...mapState(['metas']),
    meta() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].numeroDePacotesPorCicloMensal) return 10;
      return +this.metas[this.unidadeIndicadores].numeroDePacotesPorCicloMensal;
    },
  },

  data() {
    return {
      loading: false,
      loadingExport: false,
      selectedMonth: parseInt(moment().format('MM'), 10),
      selectedYear: parseInt(moment().format('yyyy'), 10),
      informacoes: {
        numberOfPacotes: 0,
        numberOfCiclos: 0,
        pacotesPorCiclo: 0,
      },
      esterilizacoesGrouped: {},
      chartOptions: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 40,
          },
        ],
        height: '220px',
        legend: { show: true, formatter: '{name}', icon: 'none' },
        xAxis: [
          {
            show: true,
            type: 'category',
            data: [],
            axisLabel: {
              interval: 0,
            },
            axisTick: { show: false },
          },
        ],
        yAxis: [{ show: false }],
        series: [
          {
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      },
    };
  },

  methods: {
    async updateAll() {
      this.loading = true;
      await this.updateInformacoes();
      await this.updateChart();
      this.loading = false;
    },

    async updateInformacoes() {
      const selectedDate = moment()
        .month(this.selectedMonth - 1)
        .year(this.selectedYear);

      try {
        // para evitar divergencia mínima de dados estamos pegando o numberOfPacotes
        //   da mesma forma que é pega para calculo de producao
        const esterilizacoes = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: selectedDate.clone().startOf('month').toISOString(),
              endDate: selectedDate.clone().endOf('month').toISOString(),
            },
          },
          'home/esterilizacoesGroupedByTipo',
        );
        this.esterilizacoesGrouped = esterilizacoes.pacotes;

        this.informacoes.numberOfCiclos = esterilizacoes.quantidadeLotes;
        this.informacoes.numberOfPacotes = Object.keys(esterilizacoes.pacotes)
          .reduce((sum, key) => sum + +esterilizacoes.pacotes[key], 0);
        this.informacoes.pacotesPorCiclo = this.informacoes.numberOfCiclos
          ? this.informacoes.numberOfPacotes / this.informacoes.numberOfCiclos : 0;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos aos número de pacotes por ciclo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
    },

    async updateChart() {
      const chartColors = ['#209F85', '#FF7D29', '#21CCA9', '#73E5CF'];
      this.chartOptions.series[0].data = [];
      this.chartOptions.xAxis[0].data = [];
      Object.keys(this.esterilizacoesGrouped).forEach((tipoEsterilizacao, index) => {
        this.chartOptions.series[0].data.push({
          itemStyle: { color: chartColors[index] },
          value: this.esterilizacoesGrouped[tipoEsterilizacao],
        });

        this.chartOptions.xAxis[0].data.push(tipoEsterilizacao);
      });
    },

    async exportData() {
      this.loadingExport = true;

      const selectedDate = moment()
        .month(this.selectedMonth - 1)
        .year(this.selectedYear);
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: selectedDate.clone().startOf('month').toISOString(),
        endDate: selectedDate.clone().endOf('month').toISOString(),
        id_tipo_esterilizacao: [1, 2, 3],
        orderBy: 'lote',
      };
      const exportedData = await this.getExportedData(
        'home/allEsterilizacoes/export',
        filters,
        'Erro ao recuperar dados de ciclos para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      const fileName = `Processamento por Ciclo ${selectedDate.format('MMMM')}`;
      this.createXlsx(processosExported, fileName);
      this.loadingExport = false;
    },
  },

  mounted() {
    this.updateAll();
  },
};
</script>

<style>
.div-informacao {
  border-style: solid;
  box-sizing: border-box;
  border-width: 1px;
  border-radius: 10px;
  border-color: #cfd1e5;
  text-align: center;
  padding-top: 10px;
  margin-top: 5px;
}
.title-informacao {
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #7e829f;
  font-weight: 500;
}

.text-informacao {
  font-size: 36px;
}
.sub-text {
  font-size: 12px;
}

p {
  margin-bottom: 2px;
}
.mt--1-8rem {
  margin-top: -1.8rem;
}
.mb--2rem {
  margin-bottom: -2rem;
}
</style>
