<template>
  <div class="semester-container">
    <div class="align-middle">
      <div
        class="arrows left-button"
        font-scale="1.5"
        type="button"
        @click="changeSemester(-1)"
        :class="disabled ? 'arrow-disabled' : ''"
      >
        <chevron-left-icon class="chevron-icon" />
      </div>
    </div>
    <span class="span-semester">{{ year }}, {{ semester }}°Semestre</span>
    <div class="align-middle">
      <div
        class="arrows right-button"
        font-scale="1.5"
        type="button"
        @click="changeSemester(1)"
        :class="disabled ? 'arrow-disabled' : ''"
      >
        <chevron-right-icon class="chevron-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SemesterCarousel',
  props: {
    // Default value of the input, also used for edition
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      year: parseInt(moment().format('yyyy'), 10),
      semester: 1,
      beginDate: null,
      endDate: null,
      semestersMonths: [
        {
          beginMonth: 1,
          lastMonth: 6,
        },
        {
          beginMonth: 7,
          lastMonth: 12,
        },
      ],

    };
  },

  methods: {
    changeSemester(value) {
      if (this.disabled) return;
      this.semester += value;
      if (this.semester < 1) {
        this.semester = 2;
        this.year -= 1;
      } else if (this.semester > 2) {
        this.semester = 1;
        this.year += 1;
      }
      this.onChangeSemester();
    },
    onChangeSemester() {
      const { year } = this;
      const { beginMonth } = this.semestersMonths[this.semester - 1];
      const { lastMonth } = this.semestersMonths[this.semester - 1];

      this.beginDate = moment().year(year).month(beginMonth - 1).startOf('month');
      this.endDate = moment().year(year).month(lastMonth - 1).endOf('month');

      const period = {
        beginDate: this.beginDate,
        endDate: this.endDate,
        semester: this.semester,
        year: this.year,
      };

      this.$emit('onChangeSemester', period);
    },
  },

  computed: {
    currentMonth() {
      return parseInt(moment().format('MM'), 10);
    },
    currentYear() {
      return parseInt(moment().format('yyyy'), 10);
    },
  },
  mounted() {
    if (this.currentMonth >= 7) this.semester = 2;
  },
};
</script>

<style scoped>
.calendar {
  z-index: 10;
  background-color: white;
}
.semester-container {
  display: flex;
  margin-right: 15px;
  text-align: center;
}

.span-semester{
  background-color: white;
  width: 160px;
  display: inline-table;
  border-radius: 20px;
}
.arrows {
  transition: 0.3s;
  border-radius: 50%;
  cursor: pointer;
}
.arrow-disabled {
  cursor: default !important;
}

.arrows:hover {
  background-color: rgb(230, 230, 230);
}

.arrows:active {
  background-color: #8f8f8f;
}

.div-arrow-left .arrows {
  background-color: rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}

.div-arrow-right .arrows {
  background-color: rgb(230, 230, 230);
  background-position: center;
  transition: background 0.8s;
}
</style>
