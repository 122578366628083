<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Número de pacotes por ciclo</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row class="mb-4">
          <b-col sm="12" md="4" lg="3" xl="3">
            <div class="div-informacao">
              <p class="title-informacao">Meta</p>
              <p class="text-informacao">{{ meta }}</p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';

import { mapState } from 'vuex';
import ECharts from 'vue-echarts';
import GenericApi from '../../../services/genericRequest';

export default {
  components: {
    ECharts,
  },

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    selectedSemester: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      immediate: true,
      deep: true,
      handler() {
        if (this.firstUpdate) this.updateChart();
      },
    },
    selectedSemester: {
      deep: true,
      handler(semester) {
        this.selectedYear = semester.year;
        if (this.firstUpdate) this.updateChart();
      },
    },
  },

  computed: {
    ...mapState(['metas']),
    meta() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].numeroDePacotesPorCicloSemestral) return 10;
      return +this.metas[this.unidadeIndicadores].numeroDePacotesPorCicloSemestral;
    },
  },

  data() {
    return {
      firstUpdate: false,
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
      pacotesByMonth: [],
      mesesAbrev: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      chartOptions: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 38,
          },
        ],
        legend: { show: true, formatter: '{name}', icon: 'none' },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
          },
        ],
        yAxis: [{ show: false }],
        series: [
          {
            type: 'bar',
            data: [],
            label: {
              show: true,
              position: 'inside',
            },
            markLine: {
              data: [
                [
                  {
                    x: 2,
                    y: 0,
                  },
                  {
                    x: 2,
                    y: 11,
                  },
                ],
              ],
            },
          },
          {
            type: 'line',
            showSymbol: false,
            color: '#FF3348',
            data: [],
            label: {
              show: false,
            },
          },
        ],
      },
    };
  },

  methods: {
    async updateChart() {
      this.isBusy = true;
      await this.getSummaryData();
      const { pacotesByMonth } = this;
      const offsetMonthIndex = this.selectedSemester.semester === 1 ? 0 : 6;
      const xAxisData = this.mesesAbrev.slice(offsetMonthIndex, 12 + (offsetMonthIndex - 6));

      const pacoteChartData = pacotesByMonth.reduce((result, pacote) => {
        // eslint-disable-next-line dot-notation
        const indexOfMonth = pacote['mes'] - offsetMonthIndex - 1;
        const ChartDataPerMonth = result;
        ChartDataPerMonth[indexOfMonth] = {
          itemStyle: { color: '#FF6400', opacity: 0.84 },
          value: Math.round((+pacote.numberOfCiclos
            ? +pacote.numberOfPacotes / +pacote.numberOfCiclos
            : 0) * 100) / 100,
        };
        return ChartDataPerMonth;
      }, [0, 0, 0, 0, 0, 0]);

      this.chartOptions.xAxis[0].data = xAxisData;

      this.chartOptions.series[0].data = pacoteChartData;
      this.isBusy = false;
    },

    async getSummaryData() {
      const initDate = this.selectedSemester.beginDate.clone().startOf('month');
      const endDate = this.selectedSemester.endDate.clone().endOf('month');
      try {
        this.pacotesByMonth = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: initDate.toISOString(),
              endDate: endDate.toISOString(),
            },
          },
          'home/countPacotesOnEsterilizacaoByDate',
        );
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos aos procedimentos de ciclo';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
    },
  },

  async mounted() {
    this.updateChart();
    const metaData = [];
    for (let index = 0; index < 6; index++) {
      metaData.push(this.meta);
    }
    this.chartOptions.series[1].data = metaData;
    this.firstUpdate = true;
  },
};
</script>

<style>
.div-informacao {
  border-style: solid;
  box-sizing: border-box;
  border-width: 1px;
  border-radius: 10px;
  border-color: #cfd1e5;
  text-align: center;
  padding-top: 10px;
  margin-top: 5px;
}
.title-informacao {
  font-size: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #7e829f;
}

.text-informacao {
  font-size: 36px;
}
.sub-text {
  font-size: 12px;
}

p {
  margin-bottom: 2px;
}
</style>
