<template>
  <div class="div-calendar">
    <b-calendar
      ref="calendar"
      v-if="value.type !== 'month'"
      class="w-100 calendar"
      v-model="day"
      weekdayHeaderFormat="narrow"
      :date-info-fn="dateClass"
      locale="pt-BR"
      :hide-header="true"
      label-help=""
      no-highlight-today
      @input="$refs['calendar'].blur()"
    >
      <b-button
        ref="night-shift-button"
        class="btn btn-sm button--green mr-3"
        :class="shift === 'night' ? 'selected' : 'unselected'"
        variant="light"
        v-b-tooltip.hover.top="
          'Turno Noturno ( 19:00h às 07:00h do dia seguinte)'
        "
        @click="changeShift('night')"
        v-if="value.type === 'shift' && !hideButtons"
      >
        <moon-icon class="icon w20px" />
      </b-button>
      <b-button
        ref="day-shift-button"
        class="btn btn-sm button--green"
        :class="shift === 'day' ? 'selected' : 'unselected'"
        variant="light"
        v-b-tooltip.hover.top="'Turno Diurno ( 07:00h às 19:00h)'"
        @click="changeShift('day')"
        v-if="value.type === 'shift' && !hideButtons"
      >
        <sun-icon class="icon w20px" />
      </b-button>
      <b-button
        class="btn btn-sm button--green float-right"
        variant="light"
        @click="saveFilters()"
        v-if="!hideButtons"
      >
        Salvar e buscar
      </b-button>
    </b-calendar>
    <div class="outside" @click="close()"></div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data: () => ({
    day: null,
    shift: '',
  }),

  props: {
    value: {
      type: Object,
      required: true,
    },
    hideButtons: {
      type: Boolean,
      defalut: false,
    },
  },

  mounted() {
    this.shift = this.value.shift;
    this.day = this.value.day.toISOString();
  },

  methods: {
    saveFilters() {
      this.$emit('input', {
        day: moment(this.day),
        shift: this.shift,
        type: this.value.type,
      });
      this.close();
    },
    close() {
      this.$emit('close');
    },

    changeShift(shift) {
      this.shift = shift;
      this.$refs['night-shift-button'].blur();
      this.$refs['day-shift-button'].blur();
    },

    dateClass(ymd, date) {
      if (this.value.type === 'week') {
        const day = moment(date);
        if (day.startOf('day') >= moment(this.day).startOf('week')
          && day.endOf('day') <= moment(this.day).endOf('week')) {
          return 'active-date';
        }
      }
      return '';
    },
  },

};
</script>

<style>
.button--green {
  color: #00daae;
  border-style: solid;
  border-radius: 0.6rem;
  border-width: 0.15rem;
  padding: 0.4rem;
}
.button--green.unselected {
  opacity: 0.3;
}
.button--green.selected {
  background-color: rgba(0, 218, 174, 0.25) !important;
}
.button--green:hover {
  background-color: #00daae !important;
  color: white !important;
}
.button--green .icon {
  fill: #00daae;
  margin: 0;
}

.div-calendar {
  position: absolute;
  z-index: 999;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.calendar {
  margin: 1rem;
}

.calendar .form-control{
  border-width: 0;
}
.calendar .active {
  background-color: #00daae !important;
  border-radius: 5px !important;
}

.active-date {
  background-color:  rgba(0, 218, 174, 0.25);
}

.calendar .btn-outline-secondary {
  color: #00daae;
}
.calendar .b-calendar-grid-caption.font-weight-bold {
  color: #00daae;
  font-weight: 400 !important;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
}
</style>
