<template>
  <b-container class="dashboard">
    <div id="section">
      <div id="content">
        <b-row class="mb-36">
          <b-col class="pf-0" cols="8">
            <h2 id="dashboard-title">Olá, {{ user }}</h2>
          </b-col>
          <b-col offset="2">
            <b-form-select
              v-model="selectedDashboard"
              :options="dashboardOptions"
              class="invision-input"
            />
          </b-col>
        </b-row>
        <DashBoardInventario
          v-if="selectedDashboard === 'inventario' && fullReport"
          @send-selectedUnidade="setSelectedUnidade"
        />
        <DashBoardInventarioThymos
          v-else-if="selectedDashboard === 'inventario' && !fullReport"
          @send-selectedUnidade="setSelectedUnidade"
        />
        <DashBoardLogistica v-else-if="selectedDashboard === 'logistica'"/>
        <DashBoardIndicadoresMensal v-else-if="selectedDashboard === 'indicadoresMensal'"
          :unidadeIndicadores="unidadeIndicadores"
          @change-unidade-indicadores="v => unidadeIndicadores = v"
        />
        <DashBoardIndicadoresSemestral v-else-if="selectedDashboard === 'indicadoresSemestral'"
          :unidadeIndicadores="unidadeIndicadores"
          @change-unidade-indicadores="v => unidadeIndicadores = v"
        />
        <DashBoardProdutividade v-else-if="selectedDashboard === 'produtividade'"
          :unidadeIndicadores="unidadeIndicadores"
          @change-unidade-indicadores="v => unidadeIndicadores = v"
        />
        <DashBoardProcessamento v-else-if="selectedDashboard === 'processamento'"
          :unidadeIndicadores="unidadeIndicadores"
          @change-unidade-indicadores="v => unidadeIndicadores = v"
        />
        <DashBoardGeral v-else-if="selectedDashboard === 'geral'"
          :unidadeIndicadores="unidadeIndicadores"
          @change-unidade-indicadores="v => unidadeIndicadores = v"
        />
        <HomeCCI v-else-if="selectedDashboard === 'cci'" />
      </div>
    </div>
  </b-container>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart, LineChart } from 'echarts/charts';
import { TooltipComponent, LegendComponent, GridComponent } from 'echarts/components';

import { mapState } from 'vuex';
import XLSX from 'xlsx';
import login from '../../services/login';
import GenericApi from '../../services/genericRequest';

import DashBoardInventario from './Inventario/DashBoardInventario';
import DashBoardLogistica from './Logistica/DashBoardLogistica';
import DashBoardIndicadoresMensal from './Indicadores/DashBoardIndicadoresMensal';
import DashBoardIndicadoresSemestral from './Indicadores/DashBoardIndicadoresSemestral';
import DashBoardProdutividade from './Produtividade/DashBoardProdutividade';
import HomeCCI from './CCI/HomeCCI';
import DashBoardProcessamento from './Processamento/DashBoardProcessamento';
import DashBoardGeral from './Geral/DashboardGeral';
import DashBoardInventarioThymos from './Inventario/DashBoardInventarioThymos';

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  LineChart,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  name: 'Home',
  components: {
    DashBoardInventario,
    DashBoardLogistica,
    DashBoardIndicadoresMensal,
    DashBoardIndicadoresSemestral,
    DashBoardProdutividade,
    DashBoardProcessamento,
    HomeCCI,
    DashBoardGeral,
    DashBoardInventarioThymos,
  },
  provide() {
    return {
      getExportedData: this.getExportedData,
      createXlsx: this.createXlsx,
      showModal: this.showModal,
    };
  },
  computed: {
    ...mapState({
      unidades: (state) => (
        state.genericData
        && state.genericData.unidade
      ),
      currentUnidade: (state) => state.currentUnidade,
      dashboardOptions: (state) => (
        state.dashboardOptions
          .filter((dash) => dash.id_unidade === state.currentUnidade.id_unidade)
          .map((dash) => ({
            value: dash.tipoDashboard.chave,
            text: dash.tipoDashboard.descricao,
          }))
      ),
    }),

    hasUnidades: function hasUnidades() {
      return this.unidades && this.unidades.length > 1;
    },

    shownIdsParaEncerrar() {
      return this.modalOptions.materiais.reduce(
        (ids, item) => ids.concat(item.encerrarCiclo ? [item.ID] : []), [],
      );
    },

    justificativaState() {
      return Boolean(this.justificativa);
    },

    rows() {
      return this.modalOptions.materiais.length;
    },
  },

  data() {
    return {
      user: login.getUser().nome,
      selectedDashboard: [],
      selectedUnidade: null,
      unidadeIndicadores: 1,
      withoutPagination: false,
      hasNotificacao: false,
      perPage: 10,
      currentPage: 1,
      loading: false,
      filters: {},
      notification: [],
      errMsg: '',
      justificativa: '',
      wantsToForceEndCicle: false,
      screenWidth: window.innerWidth,
      materiaisParados: {},
      modalOptions: {
        materiais: [],
        unidade: '',
        processo: '',
      },
      fullReport: Boolean(process.env.VUE_APP_PROJECT === 'materdei'),
    };
  },

  methods: {
    handlePrinterPagination() {
      this.withoutPagination = !this.withoutPagination;
    },

    setSelectedUnidade(id_unidade) {
      this.selectedUnidade = id_unidade;
    },

    clearMateriaisParaEncerrar() {
      this.modalOptions.materiais.forEach((_, index) => {
        this.modalOptions.materiais[index].encerrarCiclo = false;
      });
    },

    convertMinsToHoraMins(mins) {
      const hours = Math.floor(mins / 60);
      const hoursStr = hours ? `${hours}h ` : '';
      const minutes = mins % 60;
      const minStr = minutes >= 10 ? `${minutes}mins` : `0${minutes}mins`;
      return `${hoursStr}${minStr}`;
    },

    onModalHide() {
      this.clearMateriaisParaEncerrar();
    },

    async encerrarCiclo() {
      if (!this.justificativa) {
        await swal({
          title: 'Erro',
          text: 'Justificativa é obrigatória para encerrar o ciclo!',
          icon: 'error',
          button: 'Continuar...',
        });

        return;
      }

      try {
        await GenericApi.create(
          {
            idsMaterial: this.idMateriasParaEncerrar,
            justificativa: this.justificativa,
            opts: { clearUnidade: true },
          },
          'forceCiclo/finishLote',
        );
        await swal({
          title: 'Bom trabalho!',
          text: 'Os ciclos foram encerrados com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });

        await this.checkMateriaisParados();

        this.clearMateriaisParaEncerrar();
        this.$refs.modalMateriaisParados.hide();
        this.$refs.modalEncerramentoCicloForcado.hide();
        this.justificativa = '';
      } catch (error) {
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          title: 'Oops!',
          text:
            errorMessage
            || 'Ocorreu um problema ao finalizar o ciclo desse material',
          icon: 'error',
          button: 'Continuar',
        });
      }
    },

    showModal() {
      this.wantsToForceEndCicle = !this.wantsToForceEndCicle;
      this.$refs.modalEncerramentoCicloForcado.show();
    },

    hidden() {
      this.wantsToForceEndCicle = !this.wantsToForceEndCicle;
      this.$refs.modalEncerramentoCicloForcado.hide();
    },

    viewMateriaisParados() {
      this.modalOptions.fields = [
        { key: 'Nome Unidade' },
        { key: 'Tipo Material' },
        { key: 'ID' },
        { key: 'Descrição' },
        { key: 'Último Processo' },
        { key: 'Horas/min atrás:' },
        { key: 'encerrarCiclo' },
      ];
      this.modalOptions.tipoDeMaterias = 'materiaisParados';

      if (!this.selectedUnidade) {
        const keys = Object.keys(this.materiaisParados);
        const unidades = ['Contorno', 'Santo Agostinho', 'Betim-Contagem'];
        this.modalOptions.materiais = keys
          .reduce(
            (Acum, key) => [
              ...Acum,
              ...this.materiaisParados[key].map((item) => ({
                ...item,
                id_unidade: key,
              })),
            ],
            [],
          )
          .sort((a, b) => b.diffMins - a.diffMins)
          .map((material) => ({
            Unidade: this.unidades[material.id_unidade],
            'Nome Unidade': unidades[material.id_unidade - 1],
            'Tipo Material': material.tipoMaterial,
            ID: material.shownId,
            Descrição: material.descricao,
            'Último Processo': material.ultimoProcesso,
            'Horas/min atrás:': this.convertMinsToHoraMins(material.diffMins),
            encerrarCiclo: false,
            id_material: material.id_material,
          }));
      } else {
        this.modalOptions.materiais = this.materiaisParados[
          this.selectedUnidade
        ]
          .sort((a, b) => b.diffMins - a.diffMins)
          .map((material) => ({
            Unidade: this.unidades[this.selectedUnidade],
            'Tipo Material': material.tipoMaterial,
            ID: material.shownId,
            id_material: material.id_material,
            Descrição: material.descricao,
            'Último Processo': material.ultimoProcesso,
            'Horas/min atrás:': this.convertMinsToHoraMins(material.diffMins),
            encerrarCiclo: false,
          }));
      }
      this.modalOptions.title = `Materiais Parados${
        !this.selectedUnidade
          ? ''
          : ` na unidade ${this.unidades[this.selectedUnidade]}`
      }`;
      this.currentPage = 1;
      this.$refs.modalMateriaisParados.show();
    },

    selectOrUnselectAllMateriais() {
      if (
        this.idMateriasParaEncerrar.length
        === this.modalOptions.materiais.length
      ) {
        this.clearMateriaisParaEncerrar();
        this.modalOptions.materiais.forEach((item, index) => {
          this.modalOptions.materiais[index].encerrarCiclo = false;
        });
        return;
      }

      this.clearMateriaisParaEncerrar();
      this.modalOptions.materiais.forEach((item, index) => {
        this.modalOptions.materiais[index].encerrarCiclo = true;
      });
    },

    materialRowClicked({ id_material }) {
      const routeData = this.$router.resolve({
        path: `material/rastrear/${id_material}`,
      });
      window.open(routeData.href, '_blank');
    },

    async openModalByNotificacao() {
      this.materiaisParados = await this.getMateriaisParados();
      this.viewMateriaisParados();
    },

    async getMateriaisParados() {
      return GenericApi.getWithoutPagination({}, 'home/materiaisParados');
    },

    async checkMateriaisParados() {
      try {
        this.materiaisParados = await GenericApi.getWithoutPagination({}, 'home/materiaisParados');
      } catch (e) {
        console.log(e);

        await swal({
          title: 'Erro',
          text: 'Erro ao buscar materiais parados! Contate o suporte.',
          icon: 'error',
          button: 'Continuar...',
        });
      }
    },

    async getExportedData(route, filters, errorMessage) {
      let result = null;
      try {
        result = await GenericApi.getWithoutPagination(
          {
            filters,
          },
          route,
        );
      } catch (error) {
        let errorText = errorMessage || 'Ocorreu um problema ao exportar os dados';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorText = error.response.data.error.errorMessage;
        }
        result = null;
        await swal({
          title: 'Erro',
          text: errorText,
          icon: 'error',
          button: 'Continuar...',
        });
      }
      return result;
    },

    async createXlsx(exportData, fileName = 'dadosExportacao', fileHeader) {
      const header = Array.isArray(fileHeader) ? [...fileHeader] : fileHeader;
      const ws = XLSX.utils.json_to_sheet(exportData, { header });

      if (fileHeader && Array.isArray(fileHeader)) {
        const lastPrintibleColumn = this.colName(fileHeader.length - 1);
        const lastWorksheetColumn = ws['!ref'][ws['!ref'].search(':') + 1];
        ws['!ref'] = ws['!ref'].replace(lastWorksheetColumn, lastPrintibleColumn);
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, ws, 'workbook_to_export');
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    },

    colName(columnNumber) {
      const ordA = 'A'.charCodeAt(0);
      const ordZ = 'Z'.charCodeAt(0);
      const len = ordZ - ordA + 1;

      let s = '';
      let n = columnNumber;
      while (n >= 0) {
        s = String.fromCharCode((n % len) + ordA) + s;
        n = Math.floor(n / len) - 1;
      }
      return s;
    },

    loadDefaultDashboard() {
      if (!this.dashboardOptions.length) {
        setTimeout(this.loadDefaultDashboard.bind(this), 100);
        return;
      }
      this.selectedDashboard = this.dashboardOptions[0].value;
    },
  },

  async created() {
    const resultNotification = await GenericApi.getAllWithoutPagination({}, 'home/notificacao/list');
    if (resultNotification) {
      this.hasNotification = true;
      this.notification = resultNotification;
    }
    this.hasNotificacao = Boolean(resultNotification);
  },

  mounted() {
    this.loadDefaultDashboard();
  },
};
</script>

<style lang="scss">
.pf-0 {
  padding-left: 0 !important;
}

.mb-36 {
  margin-bottom: 36px;
}

.dashboard h1 {
  text-align: left;
  margin-bottom: 20px;
  font-family: Poppins;
}

.dashboard h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #35384d !important;
}

.toper {
  width: 100%;
  min-height: 100px;
  border-radius: 5px;
}

.display {
  display: inline-flex;
}

.info-cards {
  width: 100%;
  min-height: 100px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
}
.info-cards:last-of-type {
  margin-right: 0px;
}
.info-cards h4 {
  font-weight: 600;
  font-size: 25px;
  color: #5e627a;

  padding-bottom: 12px;
  margin-bottom: 0;
}
.info-cards .card-number {
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  color: #ff6400;
}

.info-cards .card-number.total {
  margin-right: 12px;
}

.small-text {
  font-size: 14px;
  line-height: 21px;
  color: #35384d;
}
.info-cards .card-info-time {
  font-size: 10px;
  line-height: 15px;
  color: #5e627a;
}
.top-info {
  width: 100%;
  min-height: 100px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 5px;
}

.sidebar-title {
  margin: 0;
  padding-bottom: 24px;
}

.green-box-title {
  padding-top: 36px;
  padding-bottom: 12px;
  font-size: 16px;
}

.green-box {
  background-color: #e5fef8;
  border-radius: 10px;

  margin-left: 12px;
  margin-bottom: 12px;
  padding: 12px;
}

.green-box ul {
  padding-left: 24px;
}

.green-box li {
  color: #21cca9;
}
.green-box li h6 {
  color: #209f85;
}
.green-box .legenda {
  color: #5e627a;
  font-size: 10px;
  line-height: 15px;
}
.top-numbers {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #21cca9;
}
.top-text {
  font-size: 12px;
  line-height: 18px;
  color: #5e627a;
}

.clickable {
  cursor: pointer;
}

.mat-processados-dia {
  margin-left: 12px;
}

.mat-processados-dia .total {
  margin-right: 12px;
}

.spinner {
  position: relative;
  color: #209f85;
  width: 10rem;
  height: 10rem;
  left: calc(50% - 5rem);
}

.spinner-calendar {
  position: relative;
  color: #209f85;
  width: 3.5rem;
  height: 3.5rem;
  left: calc(50% - 1.75rem);
}

.spinner-times {
  position: relative;
  color: #209f85;
  width: 0.8rem;
  height: 0.8rem;
  left: 0;
}
.load-icon{
  color: #209f85;
}

.dash-box {
  background-color: #FFF;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.ml--15px {
  margin-left: -15px;
}
.row-dash-title {
  margin-bottom: 24px;
  line-height: 150%;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .display {
    display: block;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  .display {
    display: block;
  }
}

.div-spinner{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 10;
}
.dash-box-loading{
  background-color: #F7F7FA;
}

</style>

<style scoped>
#dashboard-title{
  font-weight: 500;
  font-size: 2em;
  line-height: 36px;
  color: #35384D;
}
.loadingReport {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
