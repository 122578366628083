<template>
  <div>
    <b-row>
      <b-col>
        <h4 class="row-dash-title">Quantidade de pacotes por Ciclo</h4>
      </b-col>
      <b-col cols="2">
        <b-form-select
          v-model="selectedDateType"
          :options="dateSelectOptions"
          class="invision-input"
        ></b-form-select>
      </b-col>
      <b-col cols="2" v-if="selectedDateType === 'day'">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
      <b-col cols="3" v-else>
        <b-form-input
          :value="getFormattedShiftDate(shiftDate, shiftDate.day)"
          class="invision-input"
          @click="showShiftCalendar = true"
          :disabled="showShiftCalendar"
        />
        <CalendarWithShift
          v-if="showShiftCalendar"
          :value=shiftDate
          @input="changeShiftDate"
          @close="showShiftCalendar = false"
        />
      </b-col>
      <b-col cols="2">
      <GenericSelect
        ref="selectTipoProcessamento"
        name="tipoProcessamento"
        labelKey="nome"
        v-model="id_tipo_esterilizacao"
        route="tipoEsterilizacao"
        :disabled="loading"
        v-validate="{ required: true }"
        :customFilters="filtersTipoEsterilizacao"
        :firstOption="selectFirstOption"
        :state="null"
        class="select-tipo-esterilizacao"
      ></GenericSelect>
      </b-col>
      <b-col cols="1" >
        <div v-if="loadingExport || loading">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericSelect from '../../../components/Form/GenericSelect';
import GenericApi from '../../../services/genericRequest';
import CalendarWithShift from '../../../components/Utils/CalendarWithShift';

export default {
  name: 'PacotesCiclo',
  components: {
    ECharts,
    RangeDatePicker,
    GenericSelect,
    CalendarWithShift,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateRange: {
        initDate: moment().subtract(2, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      },
      shiftDate: {
        type: 'shift',
        shift: 'day',
        day: moment().hours() >= 7 ? moment() : moment().subtract(1, 'day'),
      },
      filtersTipoEsterilizacao: {
        withoutTipo: '70',
      },
      selectFirstOption: {
        value: null,
        label: 'Todos',
      },
      dateSelectOptions: [
        { value: 'day', text: 'Dia' },
        { value: 'shift', text: 'Turno' },
      ],
      shiftsInitHour: {
        day: 7,
        night: 19,
      },
      selectedDateType: 'day',
      showShiftCalendar: false,
      id_tipo_esterilizacao: 1,
      loading: false,
      loadingExport: false,
      mountedFirstTime: false,
      firstUpdate: false,
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
      chartOptions: {
        legend: {
          left: '3%',
          bottom: 0,
          textStyle: {
            fontSize: 12,
          },
          selectedMode: false,
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [{
          show: true,
          splitLine: {
            show: false,
          },
          max: (value) => (value.max < 10 ? 10 : null),
        }],
        series: [{
          type: 'bar',
          name: 'Quantidade de pacotes',
          stack: 'principal',
          itemStyle: {
            color: '#21CCA9',
          },
          label: {
            show: true,
          },
          barMaxWidth: 200,
          data: [],
        },
        ],
      },
    };
  },

  computed: {
    idsTipoEsterilizacao() {
      if (this.id_tipo_esterilizacao) return [this.id_tipo_esterilizacao];
      if (!this.$refs.selectTipoProcessamento) return [];
      const ids = this.$refs.selectTipoProcessamento.options.map((item) => item.value);
      return ids;
    },
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    dateRange: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    id_tipo_esterilizacao: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },

    changeShiftDate(shiftObject) {
      this.shiftDate = shiftObject;
      this.dateRange = this.getShiftDateRange(shiftObject.day, shiftObject.shift);
    },

    getShiftDateRange(day, shift) {
      const shiftInitHour = this.shiftsInitHour[shift];
      const initDate = day.clone().set('hour', shiftInitHour);
      const endDate = initDate.clone().add(12, 'hour');
      return { initDate, endDate };
    },

    async updateChart() {
      const ciclos = await this.getChartData();
      if (ciclos && ciclos.length) {
        this.resetChartData(ciclos);
        this.updateChartData(ciclos);
      } else this.createEmptyChart();
    },

    async getChartData() {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_tipo_esterilizacao: this.idsTipoEsterilizacao,
      };
      try {
        this.loading = true;

        const ciclos = await GenericApi.getWithoutPagination(
          { filters },
          'home/pacotesByCiclo',
        );
        return ciclos;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à ciclos por dia';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    updateChartData(ciclos) {
      const numberOfCiclos = ciclos.length;
      this.chartOptions.xAxis[0].data = Array.from({ length: numberOfCiclos }, (v, k) => `Ciclo ${k + 1}`);
      ciclos.forEach((ciclo) => {
        this.chartOptions.series[0].data.push(ciclo.numberOfPacotes);
      });
    },

    resetChartData() {
      this.chartOptions.series[0].data = [];
      this.chartOptions.xAxis[0].data = [];
    },

    createEmptyChart() {
      this.chartOptions.series[0].data = [0];
      this.chartOptions.xAxis[0].data = ['Ciclo 1'];
    },

    getFormattedShiftDate(object, date) {
      return `${date.format('DD MMM YYYY')} - Turno ${object.shift === 'day' ? 'Diurno' : 'Noturno'}`;
    },
    async exportData() {
      this.loadingExport = true;

      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_tipo_esterilizacao: this.idsTipoEsterilizacao,
        orderBy: 'lote',
      };
      const exportedData = await this.getExportedData(
        'home/processamento/export',
        filters,
        'Erro ao recuperar dados de processamentos diários para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      this.createXlsx(processosExported, 'Processamentos por Ciclo');
      this.loadingExport = false;
    },

  },
};
</script>

<style>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
/* .select-tipo-esterilizacao{
  width: auto;
} */
</style>
