<template>
  <div>
  <b-row>
    <b-col cols="12">
      <div class="meta-box">
      <div class="info-text">TOTAL DE PERDAS</div>
      {{ totalPerdas }} - {{ porcentagemPerda }}{{'%'}}
      <span
        :class="diferencaMeta > 0 ? 'negativo' : 'positivo'"
        class="result-meta"
      >
        <arrow-down-icon v-if="porcentagemPerda < meta * 100"/>
        <arrow-up-icon v-else-if="porcentagemPerda > meta * 100"/>
        {{ `${diferencaMeta}%` }}
      </span>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="12">
      <ECharts
        @click="chartClick"
        :option="chartOptions"
      />
    </b-col>
  </b-row>
</div>
</template>

<script>
import ECharts from 'vue-echarts';

export default {
  name: 'ControleBnpBarra',
  components: {
    ECharts,
  },
  props: {
    perdasByUnidade: {
      type: Array,
      required: true,
    },
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    meta: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 38,
          },
        ],
        color: [
          '#FF7D29',
          '#FF3348',
        ],
        legend: {
          data: ['Perdas', 'Defeitos'],
          right: '10%',
          top: 0,
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [{
          show: true,
          type: 'category',
          axisTick: { show: false },
          data: [],
        }],
        yAxis: [{
          show: false,
        }],
        series: [
          {
            name: 'Perdas',
            type: 'bar',
            stack: 'perdas',
            data: [],
            label: {
              show: true,
              position: 'top',
            },
          }, {
            type: 'bar',
            name: 'Defeitos',
            stack: 'defeitos',
            data: [],
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      },
      totalPerdas: 0,
      totalMateriais: 0,
    };
  },

  watch: {
    perdasByUnidade: {
      deep: true,
      immediate: true,
      handler() {
        this.onChartData(this.perdasByUnidade);
      },
    },
  },

  computed: {
    porcentagemPerda() {
      if (this.totalMateriais === 0) return 0;
      return ((this.totalPerdas * 100) / this.totalMateriais).toFixed(1);
    },

    diferencaMeta() {
      if (this.totalMateriais === 0) return 0;
      return (((this.totalPerdas / this.totalMateriais) - this.meta) * 100).toFixed(1);
    },
  },

  methods: {
    chartClick() {},

    onChartData(perdas) {
      this.parsePerdasData(perdas);
    },

    parsePerdasData(perdas) {
      this.chartOptions.xAxis[0].data = [];
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];

      this.totalPerdas = 0;
      this.totalMateriais = 0;

      perdas.forEach((perda) => {
        this.chartOptions.xAxis[0].data.push(perda.unidade);

        this.chartOptions.series[0].data.push(perda.perdas);
        this.chartOptions.series[1].data.push(perda.defeitos);

        this.totalPerdas += perda.perdas + perda.defeitos;
        this.totalMateriais += perda.quantidade_itens;
      });
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #CFD1E5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
</style>
