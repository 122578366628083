<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="8">
        <h4 class="row-dash-title ml--15px">Controle de BNP</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="isBusy"
      rounded="sm"
      class="dashboard-overlay"
    >
      <b-row>
        <div class="dash-box">
          <b-row>
            <b-col sm="12" md="4" lg="3" xl="3">
              <div class="div-informacao">
                <p class="title-informacao">Limite</p>
                <p class="text-informacao">{{ `${chartMeta}` + '%' }}</p>
              </div>
            </b-col>
            <b-col sm="12" md="8" lg="9" xl="9">
              <b-row align-h="end">
                <b-col v-for="(perdas, index) in chartDataByUnidade" :key="index" cols="3">
                  <div class="subtitle">
                    <label :style="`background-color: ${chartOptions.color[index]};`" class="label-square"></label>
                    <span>{{ perdas.unidade }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <ECharts :option="chartOptions" />
            </b-col>
          </b-row>
        </div>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';

import { mapState } from 'vuex';
import ECharts from 'vue-echarts';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'ControleBnpSemestral',
  components: {
    ECharts,
  },

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    selectedSemester: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      immediate: true,
      deep: true,
      handler() {
        if (this.firstUpdate) this.updateChart();
      },
    },
    selectedSemester: {
      deep: true,
      handler(semester) {
        this.selectedYear = semester.year;
        if (this.firstUpdate) this.updateChart();
      },
    },
  },

  data() {
    return {
      firstUpdate: false,
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
      chartDataByUnidade: [],
      mesesAbrev: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      chartOptions: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 40,
            borderColor: '#D4D4D4',
          },
        ],
        color: ['#FF7D29', '#209F85', '#21CCA9', '#73E5CF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function tooltipFormatter(params) {
            const tooltip = params.reduce((htmlTooltip, unidade) => {
              const valueHtml = `${htmlTooltip} <br> ${unidade.marker} ${unidade.seriesName} &nbsp &nbsp
                <span style="float:right">${unidade.value}%</span>`;
              return valueHtml;
            }, `${params[0].name} `);
            return tooltip;
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun'],
          },
        ],
        yAxis: [{ show: false }],
        series: [
          {
            type: 'line',
            name: 'Limite',
            showSymbol: false,
            color: '#FF3348',
            data: [],
            label: {
              show: false,
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapState(['metas', 'configuracao']),

    chartMeta() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].limiteDePerdasSemestral) return 1;
      return +this.metas[this.unidadeIndicadores].limiteDePerdasSemestral;
    },
  },

  async mounted() {
    await this.updateChart();
    this.firstUpdate = true;
  },

  methods: {
    async updateChart() {
      this.isBusy = true;

      await this.getSummaryData();
      const offsetMonthIndex = this.selectedSemester.semester === 1 ? 0 : 6;

      this.chartOptions.series.forEach((series) => {
        series.data = [0, 0, 0, 0, 0, 0];
      });
      await this.$nextTick();

      this.chartOptions.series.length = 1;
      this.chartDataByUnidade.forEach((unidadeData, index) => {
        this.chartOptions.series.push({
          type: 'bar',
          name: unidadeData.unidade || this.configuracao.nomeRede || 'Rede',
          stack: 'principal',
          itemStyle: {
            color: this.chartOptions.color[index],
          },
          data: unidadeData.dataByMonth.map((monthData) => monthData.porcentagemPerdaDefeito),
        });
      });

      this.chartOptions.xAxis[0].data = this.mesesAbrev.slice(offsetMonthIndex, 12 + (offsetMonthIndex - 6));

      this.chartOptions.series[0].data = [];
      for (let i = 0; i < 6; i++) {
        this.chartOptions.series[0].data.push(this.chartMeta);
      }

      this.isBusy = false;
    },

    async getSummaryData() {
      const initDate = this.selectedSemester.beginDate.clone().startOf('month');
      const endDate = this.selectedSemester.endDate.clone().endOf('month');
      try {
        const perdasByUnidade = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: initDate.toISOString(),
              endDate: endDate.toISOString(),
            },
          },
          'home/BNPGroupedByUnidadeAndMonth',
        );
        this.organizeApiData(perdasByUnidade);
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos ao BNP';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      }
    },

    organizeApiData(perdasByUnidade) {
      const offsetMonthIndex = this.selectedSemester.semester === 1 ? 1 : 7;

      const unidades = perdasByUnidade.reduce((un, perdas) => {
        const unidadeExiste = un.some((u) => u === perdas.unidade);
        if (!unidadeExiste) un.push(perdas.unidade);
        return un;
      }, []);

      const organizedPerdaAndCiclosData = unidades.map(((unidade) => {
        const months = [];
        for (let mesIndex = 0; mesIndex < 6; mesIndex++) {
          months.push({
            quantidade_itens: 0,
            defeitos: 0,
            perdas: 0,
            porcentagemPerdaDefeito: 0,
            mes: mesIndex + offsetMonthIndex,
          });
        }

        return {
          unidade,
          dataByMonth: months,
        };
      }));

      perdasByUnidade.forEach((perdasDefeitos) => {
        const {
          unidade, mes, perdas, defeitos, quantidade_itens,
        } = perdasDefeitos;
        const indexUnidade = organizedPerdaAndCiclosData.findIndex((el) => el.unidade === unidade);
        const indexMes = mes - offsetMonthIndex;
        organizedPerdaAndCiclosData[indexUnidade].dataByMonth[indexMes].quantidade_itens = quantidade_itens;
        organizedPerdaAndCiclosData[indexUnidade].dataByMonth[indexMes].perdas = perdas;
        organizedPerdaAndCiclosData[indexUnidade].dataByMonth[indexMes].defeitos = defeitos;
        organizedPerdaAndCiclosData[indexUnidade].dataByMonth[indexMes].mesPerda = mes;

        const porcentagemPerdaDefeito = quantidade_itens
          ? ((perdas + defeitos) * 100) / quantidade_itens
          : 0;

        organizedPerdaAndCiclosData[indexUnidade]
          .dataByMonth[indexMes].porcentagemPerdaDefeito = Math.round(porcentagemPerdaDefeito);
      });

      this.chartDataByUnidade = organizedPerdaAndCiclosData;
    },
  },
};
</script>

<style scoped>
.label-square {
  height: 6%;
  border-radius: 10%;
  width: 11%;
  position: absolute;
  margin: 0;
  border-radius: 0.1rem;
}

.label-square.rede {
  background-color: #c2f2ea;
}

.label-square.contorno {
  background-color: #21cca9;
}

.label-square.santo-agostinho {
  background-color: #f8c51a;
}

.label-square.betim {
  background-color: #3989bf;
}

.subtitle {
  display: flex;
  justify-content: 'space-evenly';
  align-items: center;
  margin-bottom: 1rem;
}

.subtitle span {
  font-weight: 500;
  font-size: 12px;
  margin-left: 2rem;
}
</style>
