<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="8">
        <h4 class="row-dash-title ml--15px">Número de materiais processados</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5" class="p-0">
        <b-overlay
          :show="isBusy"
          rounded="sm"
          class="dashboard-overlay"
        >
          <div class="dash-box">
            <DadosProcessadosRosca
              :metaUnidade="metaUnidade"
              :tiposProcessosRealizados="tiposProcessosRealizados"
              tipoMeta="SEMESTRAL"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col cols="7" class="pr-0">
        <b-overlay
          :show="isBusy"
          rounded="sm"
          class="dashboard-overlay"
        >
          <div class="dash-box">
            <DadosProcessadosBarra
              :semestre="selectedSemester.semester"
              :tiposProcessosRealizados="tiposProcessosRealizados"
              :metaUnidade="metaUnidade"
              tipoMeta="SEMESTRAL"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import GenericApi from '../../../services/genericRequest';
import DadosProcessadosRosca from './DadosProcessadosRosca';
import DadosProcessadosBarra from './DadosProcessadosBarra';

export default {
  name: 'ProcessadosSemestral',
  components: {
    DadosProcessadosRosca,
    DadosProcessadosBarra,
  },

  data() {
    return {
      firstUpdate: false,
      tiposProcessosRealizados: [],
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
    };
  },

  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    selectedSemester: {
      type: Object,
      required: true,
    },
  },

  watch: {
    unidadeIndicadores: {
      deep: true,
      handler() {
        if (this.firstUpdate) this.getSummaryData();
      },
    },
    selectedSemester: {
      deep: true,
      handler(semester) {
        this.selectedYear = semester.year;
        if (this.firstUpdate) this.getSummaryData();
      },
    },
  },

  computed: {
    ...mapState(['metas']),
    metaUnidade() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].numeroDeMateriaisProcessadosSemestral) return 60000;
      return +this.metas[this.unidadeIndicadores].numeroDeMateriaisProcessadosSemestral;
    },
  },

  methods: {
    async getSummaryData() {
      this.isBusy = true;
      const initDate = this.selectedSemester.beginDate.clone().startOf('month');
      const endDate = this.selectedSemester.endDate.clone().endOf('month');
      try {
        this.tiposProcessosRealizados = await GenericApi.getWithoutPagination(
          {
            filters: {
              id_unidade: this.unidadeIndicadores,
              initDate: initDate.toISOString(),
              endDate: endDate.toISOString(),
            },
          },
          'home/esterilizacoesGroupedByTipoAndMonth',
        );
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar o objeto lido';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      } finally {
        this.isBusy = false;
      }
    },
  },

  mounted() {
    this.getSummaryData();
    this.firstUpdate = true;
  },
};
</script>

<style scoped>
.load-icon {
  line-height: 1;
  font-size: 36px;
  font-weight: 600;
  color: #209F85;
}
.loading-div {
  text-align: center;
}
</style>
