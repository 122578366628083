<template>
  <div>
    <ECharts @click="chartClick" :option="chartOptions" />
    <div class="info-box">
      <b-row>
        <b-col cols="6">
          <label class="info-text">META {{ tipoMeta }}</label>
          <br />
          <label class="meta-text">{{ meta }}</label>
        </b-col>
        <b-col cols="6">
          <label class="info-text">ALCANCE DA META</label>
          <br />
          <label
            class="meta-text"
            :class="
              quantidadeTotal >= meta
                ? 'resultado-positivo'
                : 'resultado-negativo'
            "
          >
            {{ porcentagemMeta }}
            <arrow-up-icon class="arrow-icon" v-if="quantidadeTotal > meta" />
            <arrow-down-icon
              class="arrow-icon"
              v-else-if="quantidadeTotal < meta"
            />
          </label>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col v-for="(tipoProcesso, index) in tiposProcesso" :key="`tipoProcesso${index}`" cols="6">
          <label :style="`background-color: ${chartOptions.color[index]};`" class="label-square"></label>
          <br />
          <label class="total-text mb-0">{{ tipoProcesso.total }}</label>
          <br />
          <label class="info-text mt-0">{{ tipoProcesso.name.toUpperCase() }}</label>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';

export default {
  name: 'DadosProcessadosRosca',
  components: {
    ECharts,
  },
  props: {
    tiposProcessosRealizados: {
      type: Array,
      required: true,
    },
    semestral: {
      type: Boolean,
      default: false,
    },
    metaUnidade: {
      type: Number,
    },
    tipoMeta: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      chartOptions: {
        color: ['#209F85', '#21CCA9', '#FF7D29', '#73E5CF'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '30%'],
            center: ['15%', '20%'],
            avoidLabelOverlap: false,
            label: {
              color: '#000',
              fontSize: '1rem',
              position: 'center',
              formatter: '1',
            },
            data: [],
          },
        ],
      },
      tiposProcesso: [],
    };
  },

  watch: {
    tiposProcessosRealizados: {
      deep: true,
      immediate: true,
      handler() {
        this.onChartData(this.tiposProcessosRealizados);
      },
    },
  },

  computed: {
    porcentagemMeta() {
      if (this.meta === 0) return '-';
      const diferenca = this.quantidadeTotal - this.meta;
      const porcentagem = (diferenca / this.meta) * 100;
      return `${Math.abs(porcentagem).toFixed(1)}%`;
    },
    meta() {
      return this.metaUnidade;
    },
    quantidadeTotal() {
      return this.tiposProcesso.reduce((sum, tp) => sum + tp.total, 0);
    },
  },

  methods: {
    chartClick() {},

    onChartData(processos) {
      this.tiposProcesso = [];

      this.chartOptions.series[0].data = processos.reduce((total, processo) => {
        let serieAtual = total.find((t) => t.name === processo.tipoProcesso);
        if (!serieAtual) {
          serieAtual = {
            name: processo.tipoProcesso,
            value: 0,
          };
          total.push(serieAtual);
        }
        serieAtual.value += processo.total;

        let tipoProcessoAtual = this.tiposProcesso.find((t) => t.name === processo.tipoProcesso);
        if (!tipoProcessoAtual) {
          tipoProcessoAtual = {
            name: processo.tipoProcesso,
            total: 0,
          };
          this.tiposProcesso.push(tipoProcessoAtual);
        }
        tipoProcessoAtual.total += processo.total;

        return total;
      }, []);

      this.chartOptions.series[0].label.formatter = `${this.quantidadeTotal}`;
    },
  },
};
</script>

<style scoped>
.info-box {
  position: absolute;
  width: 65%;
  height: 90%;
  top: 10%;
  left: 35%;
}

.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.meta-text {
  font-weight: 500;
  font-size: 1.6rem;
}
.meta-text.resultado-positivo {
  color: green;
}
.meta-text.resultado-negativo {
  color: red;
}
.meta-text .arrow-icon {
  position: absolute;
  height: 20%;
  top: 48%;
}

.total-text {
  font-weight: 500;
  font-size: 1.3rem;
}
.label-square {
  height: 15%;
  border-radius: 10%;
  width: 20%;
  position: absolute;
  margin: 0;
}

.label-square.vapor {
  background-color: #209f85;
}
.label-square.termodesinfeccao {
  background-color: #ff7d29;
}
.label-square.peroxido {
  background-color: #21cca9;
}
.label-square.alcool {
  background-color: #73e5cf;
}
</style>
