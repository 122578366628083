<template>
  <div class="mb-5">
    <b-row>
      <b-col cols="6">
        <h4 class="row-dash-title ml--15px">Controle de BNP</h4>
      </b-col>
      <b-col cols="6">
        <div v-if="loadingExport || loading" class="float-right">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else class="float-right">
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5" class="p-0">
        <b-overlay
          :show="loading"
          rounded="sm"
          class="dashboard-overlay"
        >
        <div class="dash-box">
            <ControleBnpRosca
              :perdasByUnidade="perdasByUnidade"
              :unidadeIndicadores="unidadeIndicadores"
              :meta="meta"
            />
        </div>
        </b-overlay>
      </b-col>
      <b-col cols="7" class="pr-0">
        <b-overlay
          :show="loading"
          rounded="sm"
          class="dashboard-overlay"
        >
          <div class="dash-box">
            <ControleBnpBarra
              :perdasByUnidade="perdasByUnidade"
              :unidadeIndicadores="unidadeIndicadores"
              :meta="meta"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';

import { mapState } from 'vuex';
import GenericApi from '../../../services/genericRequest';
import ControleBnpRosca from './ControleBnpRosca';
import ControleBnpBarra from './ControleBnpBarra';

export default {
  name: 'ControleBnpMensal',
  components: {
    ControleBnpRosca,
    ControleBnpBarra,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      perdasByUnidade: [],
      selectedMonth: parseInt(moment().format('MM'), 10),
      selectedYear: parseInt(moment().format('yyyy'), 10),
      loading: true,
      loadingExport: false,
    };
  },

  computed: {
    ...mapState(['metas']),
    meta() {
      if (!this.metas || !this.metas[this.unidadeIndicadores]
          || !this.metas[this.unidadeIndicadores].limiteDePerdasMensal) return 0.01;
      return +this.metas[this.unidadeIndicadores].limiteDePerdasMensal;
    },
  },

  mounted() {
    this.getSummaryData();
  },

  watch: {
    unidadeIndicadores() {
      this.getSummaryData();
    },
    date: {
      deep: true,
      handler(d) {
        this.selectedMonth = d.monthIndex;
        this.selectedYear = d.year;
        this.getSummaryData();
      },
    },
  },

  methods: {
    async getSummaryData() {
      this.loading = true;
      const selectedDate = moment().month(this.selectedMonth - 1).year(this.selectedYear);
      try {
        this.perdasByUnidade = await GenericApi.getWithoutPagination(
          {
            filters: {
              initDate: selectedDate.clone().startOf('month').toISOString(),
              endDate: selectedDate.clone().endOf('month').toISOString(),
              unidades: this.unidadeIndicadores,
            },
          },
          'home/BNPGroupedByUnidade',
        );
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos ao controle de BNP';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
      } finally {
        this.loading = false;
      }
    },

    async exportData() {
      this.loadingExport = true;
      const selectedDate = moment().month(this.selectedMonth - 1).year(this.selectedYear);
      const filters = {
        initDate: selectedDate.clone().startOf('month').toISOString(),
        endDate: selectedDate.clone().endOf('month').toISOString(),
        unidades: this.unidadeIndicadores,
      };
      const exportedData = await this.getExportedData(
        'home/BNP/export',
        filters,
        'Erro ao recuperar dados de BNP para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      const fileName = `Processamento BNP ${selectedDate.format('MMMM')}`;
      this.createXlsx(processosExported, fileName);
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.load-icon {
  line-height: 1;
  font-size: 36px;
  font-weight: 600;
  color: #209F85;
}
.loading-div {
  text-align: center;
}
</style>
