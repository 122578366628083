<template>
  <div>
    <ECharts
      @click="chartClick"
      :option="chartOptions"
    />
</div>
</template>

<script>
import ECharts from 'vue-echarts';

export default {
  name: 'DadosProcessadosBarra',
  components: {
    ECharts,
  },
  props: {
    tiposProcessosRealizados: {
      type: Array,
      required: true,
    },
    semestre: {
      type: Number,
      required: false,
    },
    metaUnidade: {
      type: Number,
      required: false,
    },
    tipoMeta: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      mesesAbrev: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      chartOptions: {
        grid: [
          {
            show: false,
            left: 10,
            right: 10,
            top: 30,
            bottom: 38,
          },
        ],
        color: ['#209F85', '#21CCA9', '#FF7D29', '#73E5CF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [{
          show: true,
          type: 'category',
          axisTick: { show: false },
          data: [
            'Par Diurno',
            'Par Noturno',
            'Ímpar Diurno',
            'Ímpar Noturno',
          ],
        }],
        yAxis: [{ show: false }],
        series: [{
          type: 'line',
          name: 'META',
          showSymbol: false,
          color: '#FF3348',
          data: [0, 0, 0, 0, 0, 0],
          label: {
            show: false,
          },
        }],
      },
      totais: [0, 0, 0, 0, 0, 0],
    };
  },

  watch: {
    tiposProcessosRealizados: {
      deep: true,
      immediate: true,
      handler() {
        this.totais = this.totais.map(() => 0);
        this.setUpChartSeries();
        this.onChartData(this.tiposProcessosRealizados);
      },
    },
  },

  methods: {
    chartClick() {},

    setUpChartSeries() {
      this.chartOptions.series.forEach((series) => {
        series.data = [0, 0, 0, 0, 0, 0];
        series.label = null;
      });

      this.chartOptions.series = this.tiposProcessosRealizados
        .reduce((acum, tipo) => {
          let currentTipo = acum.find((a) => a.name === tipo.tipoProcesso.toUpperCase());
          if (!currentTipo) {
            currentTipo = {
              type: 'bar',
              name: tipo.tipoProcesso.toUpperCase(),
              stack: 'principal',
              data: [0, 0, 0, 0, 0, 0],
            };
            acum.push(currentTipo);
          } else {
            currentTipo.data = [0, 0, 0, 0, 0, 0];
            currentTipo.label = null;
          }

          return acum;
        }, this.chartOptions.series);

      if (!this.chartOptions.series.length) return;

      this.chartOptions.series[this.chartOptions.series.length - 1].label = {
        show: true,
        position: 'top',
        formatter: (params) => this.totais[params.dataIndex].toString(),
      };
    },

    onChartData(processos) {
      this.quantidadeTotal = 0;
      if (this.semestre) {
        this.updateChartSemestral(processos);
        return;
      }

      processos.forEach((processo) => {
        let index;
        if (processo.oddOrEven === 'odd') {
          if (processo.shift === 'noturno') index = 3;
          else index = 2;
        } else if (processo.shift === 'noturno') index = 1;
        else index = 0;

        const currentSeries = this.chartOptions.series.find((s) => s.name === processo.tipoProcesso.toUpperCase());

        currentSeries.data[index] += processo.total;
        this.totais[index] += processo.total;
      });

      const meta = Math.round((this.metaUnidade / 6) * 100) / 100;
      this.chartOptions.series[0].data = [meta, meta, meta, meta];
    },

    updateChartSemestral(processos) {
      const offsetMonthIndex = this.semestre === 1 ? 0 : 6;
      const xAxisData = this.mesesAbrev.slice(offsetMonthIndex, 12 + (offsetMonthIndex - 6));
      this.chartOptions.xAxis[0].data = xAxisData;

      processos.forEach((processo) => {
        const { mes } = processo;
        const index = mes - offsetMonthIndex - 1;

        const currentSeries = this.chartOptions.series.find((s) => s.name === processo.tipoProcesso.toUpperCase());
        currentSeries.data[index] += processo.total;
        this.totais[index] += processo.total;
      });

      const meta = Math.round((this.metaUnidade / 6) * 100) / 100;
      this.chartOptions.series[0].data = [meta, meta, meta, meta, meta, meta];
    },
  },
};
</script>

<style scoped>

</style>
