<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Quantidade de ciclos por máquina</h4>
      </b-col>
      <b-col cols="2" class="ml-auto">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
      <b-col cols="1">
        <div v-if="loadingExport || loading">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'CiclosMaquina',
  components: {
    ECharts,
    RangeDatePicker,
  },
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  inject: ['getExportedData', 'createXlsx'],
  data() {
    return {
      dateRange: {
        initDate: moment().subtract(2, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      },
      filtersTipoEsterilizacao: {
        withoutTipo: '70',
      },
      selectFirstOption: {
        value: null,
        label: 'todos',
      },
      loading: false,
      loadingExport: false,
      mountedFirstTime: false,
      chartOptions: {
        color: ['#FF7D29', '#21CCA9', '#C2F2EA', '#F8C51A', '#FF3348', '#3989BF'],
        legend: {
          data: [],
          left: '10%',
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
          selectedMode: false,
        },
        tooltip: {},
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
            splitLine: {
              show: false,
            },
            lineOverflow: 'truncate',
            axisLabel: {
              formatter: (value) => this.limitNameSize(value),
            },
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
          },
        ],
        series: [],
      },
    };
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    dateRange: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },

    async updateChart() {
      const esterilizacoes = await this.getChartData();
      await this.resetChartData();
      this.createEmptyChartData(esterilizacoes);
      this.updateChartData(esterilizacoes);
    },

    async getChartData() {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
      };
      try {
        this.loading = true;

        const esterilizacoes = await GenericApi.getWithoutPagination(
          { filters },
          'home/esterilizacoesGroupedByEquipamento',
        );
        return esterilizacoes;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à ciclos por dia';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    updateChartData(esterilizacoes) {
      esterilizacoes.forEach((esterilizacao, index) => {
        const currentSerie = this.chartOptions.series.find((s) => s.name === esterilizacao.tipoEsterilizacao);
        currentSerie.data[index] = esterilizacao.numberOfCiclos;
        this.chartOptions.xAxis[0].data.push(esterilizacao.nome);
      });
    },

    createEmptyChartData(esterilizacoes) {
      this.chartOptions.series = esterilizacoes.reduce((series, esterilizacao) => {
        const currentSerie = this.chartOptions.series.find((s) => s.name === esterilizacao.tipoEsterilizacao);
        if (!currentSerie) {
          series.push({
            name: esterilizacao.tipoEsterilizacao,
            type: 'bar',
            stack: 'stack',
            data: new Array(esterilizacoes.length).fill(null),
            label: {
              show: true,
              position: 'top',
              verticalAlign: 'middle',
            },
          });
        }

        const currentLegend = this.chartOptions.legend.data.find((label) => label === esterilizacao.tipoEsterilizacao);
        if (!currentLegend) this.chartOptions.legend.data.push(esterilizacao.tipoEsterilizacao);

        return series;
      }, this.chartOptions.series);
    },

    resetChartData() {
      this.chartOptions.xAxis[0].data = [];
      this.chartOptions.series.forEach((s) => { s.data = []; });
      return this.$nextTick();
    },

    limitNameSize(name) {
      return name.length < 18
        ? name
        : name.substr(0, 15).concat('...');
    },
    async exportData() {
      this.loadingExport = true;

      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        orderBy: 'equipamento',
      };
      const exportedData = await this.getExportedData(
        'home/processamento/export',
        filters,
        'Erro ao recuperar dados de processamentos diários para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      this.createXlsx(processosExported, 'Processamentos por Maquina');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
</style>
