<template>
<div>
  <div id="icone-notificacao" @click="handleClickIcon">
    <div id="badge-has-notificacao" />
    <alert-circle-icon color="#FFF"/>
  </div>
  <div id="box-content" v-if="hasNotification" style="z-index: 999;">
    <div v-for="item in notification" :key="item.title">
      <b-row id="box-notificacao">
        <b-col cols="8" class="box-descricao">
          <alert-circle-icon class="alert-box" color="#E52E41"/>
          <p>{{ item.descricao }}</p>
        </b-col>
        <b-col cols="4">
          <Button
            size="xs"
            variant="danger"
            type="outline"
            text="Visualizar"
            @click="handleClick(item)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</div>
</template>

<script>
import Button from '@/components/Utils/Button';

export default {
  components: {
    Button,
  },
  props: {
    notification: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hasNotification: false,
      totalItems: 0,
    };
  },
  methods: {
    handleClickIcon() {
      this.hasNotification = !this.hasNotification;
    },
    async handleClick(item) {
      if (item.tipo === 'reposicao') {
        this.$router.replace({ path: 'controleEstoque', query: { routeTipo: item.tipo } });
      } else if (item.tipo === 'vencimento') {
        this.$emit('open-modal-validade');
      } else if (item.tipo === 'limiteEsterilizacao') {
        this.$emit('open-modal-limite-ciclo');
      } else {
        this.$emit('open-modal');
      }
    },
  },
};
</script>

<style scoped>
#icone-notificacao {
  position: fixed;
  right: 28px;
  bottom: 30px;
  background-color: #35384D;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 8;
}
#box-content {
  position: fixed;
  top: 22rem;
  right: 3rem;
}
#box-notificacao {
  display: flex;
  width: 381px;
  height: 73px;
  background-color: #FFF;
  border-radius: 10px;
  border: 1px solid #E52E41;
  padding: 7px 10px 0px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  align-items: center;
  margin-bottom: 0.7rem;
}
#badge-has-notificacao {
  background-color: #55D1F9;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 9;
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.box-descricao {
  display: flex;
  align-items: center;
}
.alert-box {
  margin-right: 1rem;
  width: 48px;
}

</style>
