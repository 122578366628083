<template>
  <div>
    <ECharts @click="chartClick" :option="chartOptions" />
    <div class="info-box">
      <b-row>
        <b-col cols="12">
          <label class="info-text">LIMITE DE PERDAS</label>
          <br />
          <label class="meta-text">
            {{ Math.ceil(totalMateriais * meta) }} - {{ `${meta * 100}%` }}
          </label>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col
          v-for="(perda, index) in perdas"
          :key="`unidade${index}`"
          cols="6"
          class="mb-4"
        >
          <label :style="`background-color: ${chartOptions.color[index]};`" class="label-square"></label>
          <br />
          <label class="total-text mb-0">
            {{ perda.perdas }} -
            {{ porcentagem(perda.perdas, perda.quantidadeItens) }}
          </label>
          <br />
          <label class="info-text mt-0">
            {{ (perda.unidade || configuracao.nomeRede || "Rede" ).toUpperCase() }} - {{ perda.quantidadeItens }}
          </label>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ECharts from 'vue-echarts';

export default {
  name: 'ControleBnpRosca',
  components: {
    ECharts,
  },
  props: {
    perdasByUnidade: {
      type: Array,
      required: true,
    },
    meta: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        color: ['#C2F2EA', '#21CCA9', '#F8C51A', '#3989BF'],
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            type: 'pie',
            radius: ['20%', '30%'],
            center: ['15%', '20%'],
            avoidLabelOverlap: false,
            label: {
              color: '#000',
              fontSize: '1rem',
              position: 'center',
              formatter: '1',
            },
            data: [],
          },
        ],
      },
      perdas: [],
    };
  },

  watch: {
    perdasByUnidade: {
      deep: true,
      immediate: true,
      handler() {
        this.onChartData(this.perdasByUnidade);
      },
    },
  },

  computed: {
    ...mapState([
      'configuracao',
    ]),

    totalMateriais() {
      return this.perdas.reduce((sum, perda) => sum + perda.quantidadeItens, 0);
    },
    totalPerdas() {
      return this.perdas.reduce((sum, perda) => sum + perda.perdas, 0);
    },
  },

  methods: {
    chartClick() {},

    porcentagem(quantidade, total) {
      if (total === 0) return '';
      return `${Math.abs((quantidade * 100) / total).toFixed(1)}%`;
    },

    onChartData(perdas) {
      this.parsePerdasData(perdas);
    },

    parsePerdasData(perdas) {
      this.perdas = [];
      this.chartOptions.series[0].data = perdas.map((perda) => {
        this.perdas.push({
          unidade: perda.unidade,
          perdas: perda.defeitos + perda.perdas,
          quantidadeItens: perda.quantidade_itens,
        });

        return {
          name: perda.unidade,
          value: perda.quantidade_itens,
        };
      });

      this.chartOptions.series[0].label.formatter = `${this.totalMateriais}`;
    },
  },
};
</script>

<style scoped>
.info-box {
  position: absolute;
  width: 65%;
  height: 90%;
  top: 10%;
  left: 35%;
}

.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.meta-text {
  font-weight: 500;
  font-size: 1.6rem;
}
.meta-text.resultado-positivo {
  color: green;
}
.meta-text.resultado-negativo {
  color: red;
}
.meta-text .arrow-icon {
  position: absolute;
  height: 20%;
  top: 48%;
}

.total-text {
  font-weight: 500;
  font-size: 1.3rem;
}
.label-square {
  height: 15%;
  border-radius: 10%;
  width: 20%;
  position: absolute;
  margin: 0;
}

.label-square.rede {
  background-color: #c2f2ea;
}
.label-square.sa {
  background-color: #f8c51a;
}
.label-square.betim {
  background-color: #3989bf;
}
.label-square.contorno {
  background-color: #21cca9;
}
</style>
