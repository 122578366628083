<template>
  <div>
    <b-overlay
      :show="isBusy.firstChart"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="div-chart">
        <b-row>
          <b-col cols="12">
            <b-row class="div-chart-header">
              <b-col cols="5">
                <label class="chart-title">Tempo médio de transferência para unidade central</label>
              </b-col>
              <b-col cols="2 pr-2 pl-0">
                <b-form-select
                  v-model="unidadeFirstChart.value"
                  :options="unidadeFirstChart"
                  :disabled="isBusy.firstChart"
                  class="invision-input medium select-options"
                  />
              </b-col>
              <b-col cols="2 pr-0 pl-0">
                <b-dropdown
                  id="dropdown-form"
                  text="Tipos de Material"
                  ref="dropdown"
                  menu-class="dropdown-tipos-material-menu"
                  right
                >
                  <b-dropdown-form>
                    <GenericMultipleSelect
                      ref="tipos-material"
                      name="tipos-material"
                      id="select-tipos-material"
                      labelKey="nome"
                      v-model="tipoMaterialFirstChart"
                      route="tipoMaterial"
                    />
                  </b-dropdown-form>
                </b-dropdown>
              </b-col>
              <b-col cols="3 pl-2">
                <RangeDatePicker
                  class="b-calendar"
                  :initDate="dateRangeFirst.initDate"
                  :endDate="dateRangeFirst.endDate"
                  :state="null"
                  :disabled="showFirstCalendar"
                  @selected="fillFirstChartData"
                />
              </b-col>
            </b-row>
            <ECharts
              @click="onFirstChartClick"
              :option="firstChart"/>
          </b-col>
          <span style="font-size: small; margin: 0 3rem 1rem 3rem">
            *  Tempo médio de transferência de materiais para a
            unidade central: Período entre o recebimento no expurgo
            da unidade de origem até a efetivação do recebimento no expurgo da unidade Contorno.
          </span>
        </b-row>
      </div>
    </b-overlay>
    <br />
    <b-overlay
      :show="isBusy.secondChart"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="div-chart">
        <b-row>
          <b-col cols="12">
            <b-row  class="div-chart-header">
              <b-col cols="5">
                <label class="chart-title">Tempo médio de processamento de materiais transferidos</label>
              </b-col>
              <b-col cols="2 pr-2 pl-0">
                <b-form-select
                  v-model="unidadeSecondChart.value"
                  :options="unidadeSecondChart"
                  :disabled="isBusy.secondChart"
                  class="invision-input medium select-options"
                  />
              </b-col>
              <b-col cols="2 pr-0 pl-0">
                <b-dropdown
                  id="dropdown-form"
                  text="Tipos de Material"
                  ref="dropdown"
                  menu-class="dropdown-tipos-material-menu"
                  right
                >
                  <b-dropdown-form>
                    <GenericMultipleSelect
                      ref="tipos-material"
                      name="tipos-material"
                      id="select-tipos-material"
                      labelKey="nome"
                      v-model="tipoMaterialSecondChart"
                      route="tipoMaterial"
                    />
                  </b-dropdown-form>
                </b-dropdown>
              </b-col>
              <b-col cols="3 pl-2">
                <RangeDatePicker
                  class="b-calendar"
                  :initDate="dateRangeSecond.initDate"
                  :endDate="dateRangeSecond.endDate"
                  :state="null"
                  :disabled="showSecondCalendar"
                  @selected="fillSecondChartData"
                />
              </b-col>
            </b-row>
            <ECharts @click="onSecondChartClick" :option="secondChart" />
          </b-col>
          <span style="font-size: small; margin: 0 3rem 1rem 3rem">
            * Tempo médio de processamento de materiais transferidos:
            Período entre a entrada no expurgo da unidade Contorno até
            a efetivação do recebimento no arsenal da unidade de origem.
            Materiais que saem da contorno direto para o setor não são
            contabilizados neste gráfico.
          </span>
        </b-row>
      </div>
    </b-overlay>
    <br />
    <b-overlay
      :show="isBusy.thirdChart"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="div-chart">
        <b-row>
          <b-col cols="12">
            <b-row  class="div-chart-header">
              <b-col cols="5">
                <label class="chart-title">Tempo médio de processamento na unidade patrimônio</label>
              </b-col>
              <b-col cols="2 pr-2 pl-0">
                <b-form-select
                  v-model="unidadeThirdChart.value"
                  :options="unidadeThirdChart"
                  :disabled="isBusy.thirdChart"
                  class="invision-input medium select-options"
                  />
              </b-col>
              <b-col cols="2 pr-0 pl-0">
                <b-dropdown
                  id="dropdown-form"
                  text="Tipos de Material"
                  ref="dropdown"
                  menu-class="dropdown-tipos-material-menu"
                  right
                >
                  <b-dropdown-form>
                    <GenericMultipleSelect
                      ref="tipos-material"
                      name="tipos-material"
                      id="select-tipos-material"
                      labelKey="nome"
                      v-model="tipoMaterialThirdChart"
                      route="tipoMaterial"
                    />
                  </b-dropdown-form>
                </b-dropdown>
              </b-col>
              <b-col cols="3 pl-2">
                <RangeDatePicker
                  class="b-calendar"
                  :initDate="dateRangeThird.initDate"
                  :endDate="dateRangeThird.endDate"
                  :state="null"
                  :disabled="showThirdCalendar"
                  @selected="fillThirdChartData"
                />
              </b-col>
            </b-row>
            <ECharts @click="onThirdChartClick" :option="thirdChart" />
          </b-col>
          <span style="font-size: small; margin: 0 3rem 1rem 3rem">
            * Tempo médio de processamento na unidade patrimônio:
            Período entre a entrada no expurgo até a finalização do
            processo de esterilização.
          </span>
        </b-row>
      </div>
    </b-overlay>

    <Modal
      ref="modal"
      :title="modal.title"
      size="xl"
    >
      <b-row>
        <b-col cols="4">Data: {{ modal.data }}</b-col>
        <b-col cols="4">Número de materiais: {{ modal.numeroMateriais }}</b-col>
        <b-col cols="4">Unidade de origem: {{ modal.unidade }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">Setor: {{ modal.setor }}</b-col>
        <b-col cols="4">Tempo médio: {{ modal.tempoMedio }}</b-col>
      </b-row>
      <b-table
        :fields="modal.tableFields"
        :items="modal.ciclos"
        class="mt-3"
        responsive
        show-empty
        empty-text="Não existem ciclos para serem exibidos"
      >
        <template #cell(material)="{ item }">
          <router-link
            v-if="item.id_material"
            :to="{ path: `/material/rastrear/${item.id_material}` }"
            target="_blank"
          >
            {{ item.material }}
          </router-link>
          <label v-else>{{ item.material }}</label>
        </template>
      </b-table>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import ECharts from 'vue-echarts';
import GenericApi from '../../../services/genericRequest';
import Modal from '../../../components/Utils/Modal';
import CONSTS from '../../../helpers/constants';
import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericMultipleSelect from '../../../components/Form/GenericMultipleSelect';

moment.locale('pt-br');

export default {
  name: 'DashBoardLogistica',
  components: {
    ECharts,
    RangeDatePicker,
    Modal,
    GenericMultipleSelect,
  },

  computed: {
    ...mapState(['genericData']),
  },

  data() {
    return {
      dateRangeFirst: {
        initDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      dateRangeSecond: {
        initDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      dateRangeThird: {
        initDate: moment().subtract(7, 'days'),
        endDate: moment(),
      },
      firstChart: {
        legend: { show: false, data: [] },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: [],
            axisLabel: {
              rotate: 30,
            },
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
      },
      secondChart: {
        legend: { show: false, data: [] },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: [],
            axisLabel: {
              rotate: 30,
            },
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
      },
      thirdChart: {
        legend: { show: false, data: [] },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: [],
            axisLabel: {
              rotate: 30,
            },
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
      },

      firstChartOptions: {
        day: '',
      },
      showFirstCalendar: false,
      firstChartData: {},

      secondChartOptions: {
        day: '',
      },
      showSecondCalendar: false,
      secondChartData: {},

      thirdChartOptions: {
        day: '',
      },
      showThirdCalendar: false,
      thirdChartData: {},

      modal: {
        title: '',
        data: '',
        numeroMateriais: 0,
        unidade: '',
        setor: '',
        tempoMedio: '',
        tableFields: [{
          key: 'numero',
          label: '#',
        }, {
          key: 'date',
          label: 'Data',
        }, {
          key: 'date1',
          label: '',
        }, {
          key: 'shownId',
          label: 'ID',
        }, {
          key: 'material',
          label: 'Descrição',
        }, {
          key: 'quantidade_itens',
          label: 'Qtd peças',
        }, {
          key: 'date2',
          label: '',
        }, {
          key: 'tempo',
          label: '',
        }],
        ciclos: [],
      },

      isBusy: {
        firstChart: false,
        secondChart: false,
        thirdChart: false,
      },
      unidadeFirstChart: [],
      unidadeSecondChart: [],
      unidadeThirdChart: [],

      tipoMaterialFirstChart: [],
      tipoMaterialSecondChart: [],
      tipoMaterialThirdChart: [],
      idTiposMaterialFirstChart: [],
      oldIdTiposMaterailFirstChart: [],
      idTiposMaterialSecondChart: [],
      oldIdTiposMaterailSecondChart: [],
      idTiposMaterialThirdChart: [],
      oldIdTiposMaterailThirdChart: [],
    };
  },
  async mounted() {
    await this.fillUnidades();
    await this.fillFirstChartData(this.dateRangeFirst.initDate, this.dateRangeFirst.endDate);
    await this.fillSecondChartData(this.dateRangeSecond.initDate, this.dateRangeSecond.endDate);
    await this.fillThirdChartData(this.dateRangeThird.initDate, this.dateRangeThird.endDate);
  },
  watch: {
    tipoMaterialFirstChart: {
      deep: true,
      immediate: false,
      async handler(v) {
        this.updateIdTiposMaterialFirstChart(v);
      },
    },
    tipoMaterialSecondChart: {
      deep: true,
      immediate: false,
      async handler(v) {
        this.updateIdTiposMaterialSecondChart(v);
      },
    },
    tipoMaterialThirdChart: {
      deep: true,
      immediate: false,
      async handler(v) {
        this.updateIdTiposMaterialThirdChart(v);
      },
    },

  },
  methods: {
    updateIdTiposMaterialFirstChart(tiposMaterial) {
      this.idTiposMaterialFirstChart = tiposMaterial.map((tm) => tm.value);
      if (this.idTiposMaterialFirstChart.length > this.oldIdTiposMaterailFirstChart.length) {
        const new_id_tipo_material = this.idTiposMaterialFirstChart
          .find((id) => !this.oldIdTiposMaterailFirstChart.includes(id));
        this.oldIdTiposMaterailFirstChart.push(new_id_tipo_material);
      } else if (this.idTiposMaterialFirstChart.length < this.oldIdTiposMaterailFirstChart.length) {
        const index_removed_tipo_material = this.oldIdTiposMaterailFirstChart
          .indexOf((id) => !this.idTiposMaterialFirstChart.includes(id));
        this.oldIdTiposMaterailFirstChart.splice(index_removed_tipo_material, 1);
      }
    },

    updateIdTiposMaterialSecondChart(tiposMaterial) {
      this.idTiposMaterialSecondChart = tiposMaterial.map((tm) => tm.value);
      if (this.idTiposMaterialSecondChart.length > this.oldIdTiposMaterailSecondChart.length) {
        const new_id_usuario = this.idTiposMaterialSecondChart
          .find((id) => !this.oldIdTiposMaterailSecondChart.includes(id));
        this.oldIdTiposMaterailSecondChart.push(new_id_usuario);
      } else if (this.idTiposMaterialSecondChart.length < this.oldIdTiposMaterailSecondChart.length) {
        const index_removed_usuario = this.oldIdTiposMaterailSecondChart
          .indexOf((id) => !this.idTiposMaterialSecondChart.includes(id));
        this.oldIdTiposMaterailSecondChart.splice(index_removed_usuario, 1);
      }
    },

    updateIdTiposMaterialThirdChart(tiposMaterial) {
      this.idTiposMaterialThirdChart = tiposMaterial.map((tm) => tm.value);
      if (this.idTiposMaterialThirdChart.length > this.oldIdTiposMaterailThirdChart.length) {
        const new_id_usuario = this.idTiposMaterialThirdChart
          .find((id) => !this.oldIdTiposMaterailThirdChart.includes(id));
        this.oldIdTiposMaterailThirdChart.push(new_id_usuario);
      } else if (this.idTiposMaterialThirdChart.length < this.oldIdTiposMaterailThirdChart.length) {
        const index_removed_usuario = this.oldIdTiposMaterailThirdChart
          .indexOf((id) => !this.idTiposMaterialThirdChart.includes(id));
        this.oldIdTiposMaterailThirdChart.splice(index_removed_usuario, 1);
      }
    },

    async onFirstChartClick(event) {
      const ids_material_acao = this.firstChartData[event.seriesName][event.name];
      const filters = { ids_material_acao };
      const result = await GenericApi.getWithoutPagination(
        { filters },
        'ciclo/getDateRecebimentosByTransporte',
      );
      this.modal.title = 'Tempo médio de transferência para unidade central';
      this.modal.data = this.firstChartOptions.day;
      this.modal.numeroMateriais = ids_material_acao.length;
      this.modal.setor = event.name;
      this.modal.unidade = event.seriesName;
      this.modal.tempoMedio = event.value ? this.getFormattedSecondsDifference(event.value) : '-';

      this.modal.tableFields.find((field) => field.key === 'date1').label = `Hora receb. expurgo ${event.seriesName}`;
      this.modal.tableFields.find((field) => field.key === 'date2').label = 'Hora receb. expurgo Unidade Central';
      this.modal.tableFields.find((field) => field.key === 'tempo').label = 'Tempo transferência';
      this.modal.ciclos = result.map((ciclo, index) => {
        const date1 = ciclo.recebimentoUnidade ? moment(ciclo.recebimentoUnidade) : '-';
        const date2 = ciclo.recebimentoCentral ? moment(ciclo.recebimentoCentral) : '-';
        return {
          numero: index + 1,
          date: ciclo.recebimentoUnidade ? moment(ciclo.recebimentoUnidade).format('DD-MM-YYYY') : '-',
          date1: date1 === '-' ? '-' : date1.format('HH:mm:ss'),
          id_material: ciclo.id_material,
          shownId: ciclo.shownId,
          material: ciclo.material,
          quantidade_itens: ciclo.quantidade_itens,
          date2: date2 === '-' ? '-' : date2.format('HH:mm:ss'),
          tempo: ciclo.encerramentoForcado ? 'Encerramento forçado' : this.getDateDifference(date2, date1),
        };
      });

      this.$refs.modal.show();
    },

    async onSecondChartClick(event) {
      const ids_material_acao = this.secondChartData[event.seriesName][event.name];
      const filters = { ids_material_acao };
      const result = await GenericApi.getWithoutPagination(
        { filters },
        'ciclo/getMateriaisRetornoArsenalUnidadeOrigem',
      );

      this.modal.title = 'Tempo médio de processamento de materiais transferidos';
      this.modal.data = this.secondChartOptions.day;
      this.modal.numeroMateriais = ids_material_acao.length;
      this.modal.setor = event.name;
      this.modal.unidade = event.seriesName;
      this.modal.tempoMedio = event.value ? this.getFormattedSecondsDifference(event.value) : '-';

      this.modal.tableFields.find((field) => field.key === 'date1').label = 'Hora receb. expurgo Unidade Central';
      this.modal.tableFields.find((field) => field.key === 'date2').label = 'Hora receb. arsenal Unidade Destino';
      this.modal.tableFields.find((field) => field.key === 'tempo').label = 'Tempo Processamento';
      this.modal.ciclos = result.map((ciclo, index) => {
        const date1 = ciclo.recebimentoCentral ? moment(ciclo.recebimentoCentral) : '-';
        const date2 = ciclo.recebimentoUnidade ? moment(ciclo.recebimentoUnidade) : '-';
        return {
          numero: index + 1,
          date: ciclo.recebimentoCentral ? moment(ciclo.recebimentoCentral).format('DD-MM-YYYY') : '-',
          date1: date1 === '-' ? '-' : date1.format('HH:mm:ss'),
          id_material: ciclo.id_material,
          shownId: ciclo.shownId,
          material: ciclo.material,
          quantidade_itens: ciclo.quantidade_itens,
          date2: date2 === '-' ? '-' : date2.format('HH:mm:ss'),
          tempo: ciclo.encerramentoForcado ? 'Encerramento forçado' : this.getDateDifference(date2, date1),
        };
      });

      this.$refs.modal.show();
    },

    async onThirdChartClick(event) {
      const ids_material_acao = this.thirdChartData[event.seriesName][event.name];
      const filters = { ids_material_acao };
      const result = await GenericApi.getWithoutPagination(
        { filters },
        'ciclo/getDateRecebimentosEsterilizacao',
      );

      this.modal.title = 'Tempo médio de processamento na unidade patrimônio';
      this.modal.data = this.thirdChartOptions.day;
      this.modal.setor = event.name;
      this.modal.numeroMateriais = ids_material_acao.length;
      this.modal.unidade = event.seriesName;
      this.modal.tempoMedio = event.value ? this.getFormattedSecondsDifference(event.value) : '-';

      this.modal.tableFields.find((field) => field.key === 'date1').label = `Receb. expurgo  ${event.seriesName}`;
      this.modal.tableFields.find((field) => field.key === 'date2').label = 'Hora final esterilização';
      this.modal.tableFields.find((field) => field.key === 'tempo').label = 'Tempo Processamento';
      this.modal.ciclos = result.map((ciclo, index) => {
        const date1 = ciclo.entradaCme ? moment(ciclo.entradaCme) : '-';
        const date2 = ciclo.esterilizacao ? moment(ciclo.esterilizacao) : '-';
        return {
          numero: index + 1,
          date: ciclo.entradaCme ? moment(ciclo.entradaCme).format('DD-MM-YYYY') : '-',
          date1: date1 === '-' ? '-' : date1.format('HH:mm:ss'),
          id_material: ciclo.id_material,
          shownId: ciclo.shownId,
          material: ciclo.material,
          quantidade_itens: ciclo.quantidade_itens,
          date2: date2 === '-' ? '-' : date2.format('HH:mm:ss'),
          tempo: ciclo.encerramentoForcado ? 'Encerramento forçado' : this.getDateDifference(date2, date1),
        };
      });

      this.$refs.modal.show();
    },

    getDateDifference(endDate, startDate) {
      if (endDate === '-' || startDate === '-') return '-';

      const duration = moment.duration(endDate.diff(startDate));
      let hours = String(duration.days() * 24 + duration.hours());
      if (hours.length === 1) hours = `0${hours}`;
      let minutes = String(duration.minutes());
      if (minutes.length === 1) minutes = `0${minutes}`;
      let seconds = String(duration.seconds());
      if (seconds.length === 1) seconds = `0${seconds}`;
      return `${hours}:${minutes}:${seconds}`;
    },

    getFormattedDate(initialDate_, lastDate_) {
      if (!initialDate_ || !lastDate_) return moment();
      const initialDate = moment(initialDate_).format('DD-MM-YYYY');
      const lastDate = moment(lastDate_).format('DD-MM-YYYY');
      if (initialDate !== lastDate) {
        return `${initialDate} até ${lastDate}`;
      }
      return initialDate;
    },

    validateQtdeDias(initialDate_, lastDate_) {
      if (!initialDate_ || !lastDate_) return null;
      const dias = (lastDate_ - initialDate_) / 1000 / 60 / 60 / 24;
      if (dias > 31) return moment(initialDate_).endOf('day').add(31, 'days');
      return moment(lastDate_);
    },

    getFormattedSecondsDifference(seconds) {
      const h = Math.floor(seconds / 60) ? `${Math.floor(seconds / 60)}h` : '';
      const m = Math.floor(seconds % 60);
      return `${h}${m}m`;
    },

    async fillFirstChartData(initialDate, lastDate_) {
      const lastDate = this.validateQtdeDias(initialDate, lastDate_);
      this.firstChartOptions.day = this.getFormattedDate(initialDate, lastDate);

      this.dateRangeFirst.initDate = initialDate;
      this.dateRangeFirst.endDate = lastDate;

      const chavesObj = Object.keys(this.firstChart);
      for (let i = 0; i < chavesObj.length; i++) {
        Reflect.deleteProperty(this.firstChart, chavesObj[i]);
      }
      this.firstChart = {
        legend: { data: [] },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
      };

      this.isBusy.firstChart = true;

      const idTipoMaterial = this.tipoMaterialFirstChart.map((tm) => tm.value);
      const idUnidade = this.unidadeFirstChart.value;

      const filters = {
        initialDate, lastDate, idTipoMaterial, idUnidade,
      };
      const result = await GenericApi.getWithoutPagination(
        { filters },
        'home/entradaFromUnidades',
      );

      this.firstChartData = result;
      if (Object.keys(result).length === 0) {
        result['*'] = {
          '': [],
        };
      }

      this.firstChart.legend.data = Object.keys(result);
      this.firstChart.series = [];
      Object.keys(result).forEach((unidade) => {
        this.firstChart.series.push({
          color: CONSTS.unidadeNameColor[unidade] || '#AFB4D6',
          name: unidade,
          type: 'bar',
          data: [],
          label: {
            show: true,
            position: 'top',
            fontWeight: 700,
            formatter: (v) => {
              const h = Math.floor(v.value / 60) ? `${Math.floor(v.value / 60)}h` : '';
              const m = Math.floor(v.value % 60);
              return `${h}${m}m`;
            },
          },
        });
      });

      this.firstChart.xAxis[0].data = [];
      const unidades = Object.keys(result);
      let setores = [];
      if (Array.isArray(unidades) && unidades.length > 0) {
        setores = Object.keys(result[unidades[0]]);
        for (let i = 1; i < unidades.length; i++) {
          const setoresAdic = Object.keys(result[unidades[i]]);
          setoresAdic.forEach((elem) => {
            const setorExist = setores.some((setor) => setor === elem);
            if (!setorExist) return setores.push(elem);
            return null;
          });
        }
      }

      setores.forEach((setor) => {
        this.firstChart.xAxis[0].data.push(setor);
        if (setor && setor !== 'null') {
          Object.keys(result).forEach((unidade) => {
            const idxMedia = result[unidade][setor].length - 1;
            const valueSetor = idxMedia ? (result[unidade][setor][idxMedia] / 60) : 0;
            this.firstChart.series
              .find((series) => series.name === unidade).data
              .push(valueSetor);

            this.firstChartData[unidade][setor] = result[unidade][setor] || [];
            if (this.firstChartData[unidade][setor].length) {
              const idxDelete = this.firstChartData[unidade][setor].length - 1;
              this.firstChartData[unidade][setor].splice(idxDelete, 1);
            }
          });
        }
      });

      this.firstChart.yAxis = [{
        type: 'value',
        axisLabel: {
          formatter: (v) => Math.round(v / 60),
        },
      }];

      this.isBusy.firstChart = false;
    },

    async fillSecondChartData(initialDate, lastDate_) {
      const lastDate = this.validateQtdeDias(initialDate, lastDate_);
      this.secondChartOptions.day = this.getFormattedDate(initialDate, lastDate);

      this.dateRangeSecond.initDate = initialDate;
      this.dateRangeSecond.endDate = lastDate;

      const chavesObj = Object.keys(this.secondChart);
      for (let i = 0; i < chavesObj.length; i++) {
        Reflect.deleteProperty(this.secondChart, chavesObj[i]);
      }
      this.secondChart = {
        legend: { data: [] },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
      };
      this.isBusy.secondChart = true;
      const idTipoMaterial = this.tipoMaterialSecondChart.map((tm) => tm.value);
      const idUnidade = this.unidadeSecondChart.value;
      const filters = {
        initialDate, lastDate, idTipoMaterial, idUnidade,
      };
      const result = await GenericApi.getWithoutPagination(
        { filters },
        'home/tempoProcessamentoUnidadeCentral',
      );
      this.secondChartData = result;
      if (Object.keys(result).length === 0) {
        result['*'] = {
          '': [],
        };
      }

      this.secondChart.legend.data = Object.keys(result);
      this.secondChart.series = [];
      Object.keys(result).forEach((unidade) => {
        this.secondChart.series.push({
          color: CONSTS.unidadeNameColor[unidade] || '#AFB4D6',
          name: unidade,
          type: 'bar',
          data: [],
          label: {
            show: true,
            position: 'top',
            fontWeight: 700,
            formatter: (v) => {
              const h = Math.floor(v.value / 60) ? `${Math.floor(v.value / 60)}h` : '';
              const m = Math.floor(v.value % 60);
              return `${h}${m}m`;
            },
          },

        });
      });

      this.secondChart.xAxis[0].data = [];
      const unidades = Object.keys(result);
      let setores = [];
      if (Array.isArray(unidades) && unidades.length > 0) {
        setores = Object.keys(result[unidades[0]]);
        for (let i = 1; i < unidades.length; i++) {
          const setoresAdic = Object.keys(result[unidades[i]]);
          setoresAdic.forEach((elem) => {
            const setorExist = setores.some((setor) => setor === elem);
            if (!setorExist) return setores.push(elem);
            return null;
          });
        }
      }

      setores.forEach((setor) => {
        this.secondChart.xAxis[0].data.push(setor);
        if (setor && setor !== 'null') {
          Object.keys(result).forEach((unidade) => {
            const idxMedia = result[unidade][setor].length - 1;
            const valueSetor = idxMedia ? (result[unidade][setor][idxMedia] / 60) : 0;
            this.secondChart.series
              .find((series) => series.name === unidade).data
              .push(valueSetor);

            this.secondChartData[unidade][setor] = result[unidade][setor] || [];
            if (this.secondChartData[unidade][setor].length) {
              const idxDelete = this.secondChartData[unidade][setor].length - 1;
              this.secondChartData[unidade][setor].splice(idxDelete, 1);
            }
          });
        }
      });
      this.secondChart.yAxis = [{
        type: 'value',
        axisLabel: {
          formatter: (v) => Math.round(v / 60),
        },
      }];
      this.isBusy.secondChart = false;
    },

    async fillThirdChartData(initialDate, lastDate_) {
      const lastDate = this.validateQtdeDias(initialDate, lastDate_);
      this.thirdChartOptions.day = this.getFormattedDate(initialDate, lastDate);
      this.dateRangeThird.initDate = initialDate;
      this.dateRangeThird.endDate = lastDate;

      this.isBusy.thirdChart = true;
      const chavesObj = Object.keys(this.thirdChart);
      for (let i = 0; i < chavesObj.length; i++) {
        Reflect.deleteProperty(this.thirdChart, chavesObj[i]);
      }
      this.thirdChart = {
        legend: { data: [] },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
      };

      const idTipoMaterial = this.tipoMaterialThirdChart.map((tm) => tm.value);
      const idUnidade = this.unidadeThirdChart.value;

      const filters = {
        initialDate, lastDate, idTipoMaterial, idUnidade,
      };
      const result = await GenericApi.getWithoutPagination(
        { filters },
        'home/tempoProcessamentoUnidadePatrimonio',
      );
      this.thirdChartData = result;
      if (Object.keys(result).length === 0) {
        result['*'] = {
          '': [],
        };
      }

      this.thirdChart.legend.data = Object.keys(result);
      this.thirdChart.series = [];
      Object.keys(result).forEach((unidade) => {
        this.thirdChart.series.push({
          color: CONSTS.unidadeNameColor[unidade] || '#AFB4D6',
          name: unidade,
          type: 'bar',
          data: [],
          label: {
            show: true,
            position: 'top',
            fontWeight: 700,
            formatter: (v) => {
              const h = Math.floor(v.value / 60) ? `${Math.floor(v.value / 60)}h` : '';
              const m = Math.floor(v.value % 60);
              return `${h}${m}m`;
            },
          },

        });
      });

      this.thirdChart.xAxis[0].data = [];
      const unidades = Object.keys(result);
      let setores = [];
      if (Array.isArray(unidades) && unidades.length > 0) {
        setores = Object.keys(result[unidades[0]]);
        for (let i = 1; i < unidades.length; i++) {
          const setoresAdic = Object.keys(result[unidades[i]]);
          setoresAdic.forEach((elem) => {
            const setorExist = setores.some((setor) => setor === elem);
            if (!setorExist) return setores.push(elem);
            return null;
          });
        }
      }

      setores.forEach((setor) => {
        this.thirdChart.xAxis[0].data.push(setor);
        if (setor && setor !== 'null') {
          Object.keys(result).forEach((unidade) => {
            const idxMedia = result[unidade][setor].length - 1;
            const valueSetor = idxMedia ? (result[unidade][setor][idxMedia] / 60) : 0;
            this.thirdChart.series
              .find((series) => series.name === unidade).data
              .push(valueSetor);

            this.thirdChartData[unidade][setor] = result[unidade][setor] || [];
            if (this.thirdChartData[unidade][setor].length) {
              const idxDelete = this.thirdChartData[unidade][setor].length - 1;
              this.thirdChartData[unidade][setor].splice(idxDelete, 1);
            }
          });
        }
      });
      this.thirdChart.yAxis = [{
        type: 'value',
        axisLabel: {
          formatter: (v) => Math.round(v / 60),
        },
      }];
      this.isBusy.thirdChart = false;
    },

    async fillUnidades() {
      let result;
      if (this.genericData.unidade) {
        result = this.genericData.unidade;
      } else {
        result = await GenericApi.getWithoutPagination({ }, 'unidade');
      }
      if (result) {
        result.forEach((und) => {
          if (und.id_unidade_central || und.id_unidade !== 1) {
            this.unidadeFirstChart.push(
              {
                text: und.nome,
                value: und.id_unidade,
              },
            );
            this.unidadeSecondChart.push(
              {
                text: und.nome,
                value: und.id_unidade,
              },
            );
          }
          this.unidadeThirdChart.push(
            {
              text: und.nome,
              value: und.id_unidade,
            },
          );
        });
      }
      this.unidadeFirstChart.value = this.unidadeFirstChart[0].value;
      this.unidadeSecondChart.value = this.unidadeSecondChart[0].value;
      this.unidadeThirdChart.value = this.unidadeThirdChart[0].value;
    },
  },
};
</script>

<style lang='scss'>
.echarts {
  width: 100%;
  height: 100%;
  min-height: 20rem;
}

.div-chart {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 2rem;
  padding-bottom: 0;
}
.chart-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
}

.invision-input.small {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  height: 27px;
}
.select-options {
  padding: 0px 8px !important;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .display {
    display: block;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  .display {
    display: block;
  }
}
</style>
