<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Quantidade de materiais esterilizados por dia</h4>
      </b-col>
      <b-col cols="2">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
      <b-col cols="2" class="">
      <GenericSelect
        ref="selectTipoProcessamento"
        name="tipoProcessamento"
        labelKey="nome"
        v-model="id_tipo_esterilizacao"
        route="tipoEsterilizacao"
        :disabled="loading"
        v-validate="{ required: true }"
        :customFilters="filtersTipoEsterilizacao"
        :firstOption="selectFirstOption"
        :state="null"
        class="select-tipo-esterilizacao"
      ></GenericSelect>
      </b-col>
      <b-col cols="1">
        <div v-if="loadingExport || loading">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericSelect from '../../../components/Form/GenericSelect';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'MateriaisEsterilizados',
  components: {
    ECharts,
    RangeDatePicker,
    GenericSelect,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateRange: {
        initDate: moment().subtract(7, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      },
      filtersTipoEsterilizacao: {
        withoutTipo: '70',
      },
      selectFirstOption: {
        value: null,
        label: 'todos',
      },
      id_tipo_esterilizacao: 1,
      loading: false,
      loadingExport: false,
      mountedFirstTime: false,
      firstUpdate: false,
      selectedYear: parseInt(moment().format('yyyy'), 10),
      isBusy: true,
      chartDataByUnidade: [],
      metaBetim: 1,
      metaContorno: 3,
      mesesAbrev: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      // TODO Remover hardcoded tipo material

      chartOptions: {
        legend: {
          left: '3%',
          bottom: 0,
          textStyle: {
            fontSize: 12,
          },
        },
        color: ['#FF7D29', '#21CCA9', '#C2F2EA', '#F8C51A', '#FF3348', '#3989BF', '#A23518', '#318911'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [{
          show: true,
          splitLine: {
            show: false,
          },
          max: (value) => (value.max < 10 ? 10 : null),
        }],
        series: [],
      },
      totais: [],
    };
  },

  computed: {
    idsTipoEsterilizacao() {
      if (this.id_tipo_esterilizacao) return [this.id_tipo_esterilizacao];
      if (!this.$refs.selectTipoProcessamento) return [];
      const ids = this.$refs.selectTipoProcessamento.options.map((item) => item.value);
      return ids;
    },
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    dateRange: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    id_tipo_esterilizacao: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },

    async updateChart() {
      const esterilizacoes = await this.getChartData();
      await this.createEmptyChartData(esterilizacoes);
      if (esterilizacoes) this.updateChartData(esterilizacoes);
    },

    async getChartData() {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_tipo_esterilizacao: this.idsTipoEsterilizacao,
      };
      try {
        this.loading = true;

        const esterilizacoes = await GenericApi.getWithoutPagination(
          { filters },
          'home/esterilizacoesGroupedByTipoMaterial',
        );
        return esterilizacoes;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à ciclos por dia';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    updateChartData(esterilizacoes) {
      this.resetChartData();
      esterilizacoes.forEach((dia) => {
        dia.forEach((esterilizacaoMaterial) => {
          if (esterilizacaoMaterial) {
            const { tipoMaterial } = esterilizacaoMaterial;
            const serieIndex = this.chartOptions
              .series.findIndex((serie) => serie.name === tipoMaterial);
            if (serieIndex !== -1) {
              const firstDayMoment = this.dateRange.initDate.startOf('day').clone();
              const diaEsterilizacao = moment(esterilizacaoMaterial.day);
              const diaIndex = diaEsterilizacao.diff(firstDayMoment, 'days');
              this.chartOptions.series[serieIndex].data[diaIndex] = esterilizacaoMaterial.count;
            }
          }
        });
      });
    },

    resetChartData() {
      this.chartOptions.series.forEach((s) => { s.data = []; });
      return this.$nextTick();
    },

    async createEmptyChartData(esterilizacoes) {
      const numberOfDays = this.dateRange.endDate.diff(this.dateRange.initDate, 'days') + 1;
      this.chartOptions.series.forEach((serie, index) => {
        this.chartOptions.series[index].data = Array(numberOfDays).fill(0);
      });

      // 1 dia é subtraido de initDay pois sera somado na função de criação do vetor de dias
      const initDay = this.dateRange.initDate.clone().subtract(1, 'day');
      this.chartOptions.xAxis[0].data = Array.from({ length: numberOfDays },
        () => initDay.add(1, 'day').format('DD/MM'));

      this.chartOptions.series.forEach((serie) => {
        serie.data = [];
      });
      await this.$nextTick();

      this.chartOptions.series = esterilizacoes.reduce((series, esterilizacaoDay) => {
        esterilizacaoDay.forEach((tipoMaterial) => {
          if (!tipoMaterial) return;

          const currentSerie = series.find((s) => s.name === tipoMaterial.tipoMaterial);
          if (!currentSerie) {
            series.push({
              type: 'bar',
              name: tipoMaterial.tipoMaterial,
              stack: 'principal',
              itemStyle: {
                color: this.chartOptions.color[this.chartOptions.series.length],
              },
              label: {
                show: true,
                position: ['50%', '50%'],
                offset: [-5, -6],
                formatter: (params) => (Number(params.value) > 0 ? params.value : ''),
              },
              data: [],
            });
          }
        });

        return series;
      }, this.chartOptions.series);
    },

    async exportData() {
      this.loadingExport = true;

      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_tipo_esterilizacao: this.idsTipoEsterilizacao,
        orderBy: 'tipoMaterial',
      };
      const exportedData = await this.getExportedData(
        'home/processamento/export',
        filters,
        'Erro ao recuperar dados de processamentos para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      this.createXlsx(processosExported, 'Processamentos por material');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
/* .select-tipo-esterilizacao{
  width: auto;
} */
</style>
