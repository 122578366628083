<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Produtividade de colaboradores por área</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="5">
                <b-dropdown
                  id="dropdown-form"
                  text="Colaboradores"
                  ref="dropdown"
                  class="m-1 mr-0 float-right"
                  menu-class="dropdown-colaboradores-menu"
                  right
                  >
                <b-dropdown-form>
                <GenericMultipleSelect
                    ref="colaboradores"
                    name="colaboradores"
                    id="select-colaboradores"
                    labelKey="registro"
                    v-model="colaboradores"
                    route="usuario"
                  />
                  <CustomButton
                    id='button-ok-colaboradores'
                    text="Ok"
                    variant="primary"
                    type="outline"
                    size="sm"
                    @click="()=> this.$refs.dropdown.hide(true)"
                  />
                </b-dropdown-form>
                </b-dropdown>
              </b-col>
              <b-col cols="1" class="center-div">
                <div v-if="loadingExport" class="center-div-content">
                  <b-spinner class="load-icon"/>
                </div>
                <div @click="exportData" class="center-div-content" v-else>
                  <external-link-icon
                    style="cursor:pointer"
                    size="1.5x"
                    v-b-tooltip.hover.right="'Exportar dados'"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <ECharts
              ref="myChart"
              id="myChart"
              :option="chartOptions"
            />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import moment from 'moment';
import GenericMultipleSelect from '../../../components/Form/GenericMultipleSelect';
import CustomButton from '../../../components/Utils/Button';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'ProdutividadeColaborador',
  components: {
    ECharts,
    GenericMultipleSelect,
    CustomButton,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingExport: false,
      colaboradores: [],
      id_usuarios: [],
      old_id_usuarios: [],
      chartOptions: {
        color: ['#FF7D29', '#3989BF', '#21CCA9', '#FF3348'],
        legend: {
          data: ['Expurgo', 'Preparo', 'Esterilização', 'Arsenal'],
          left: '10%',
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            show: false,
          },
        ],
        series: [
          {
            name: 'Expurgo',
            type: 'bar',
            stack: 'expurgo', // chave do sub-setor
            data: [0],
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            type: 'bar',
            name: 'Preparo',
            stack: 'preparo', // chave do sub-setor
            data: [0],
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            type: 'bar',
            name: 'Esterilização',
            stack: 'esterilizacao', // chave do sub-setor
            data: [0],
            label: {
              show: true,
              position: 'top',
            },
          },
          {
            type: 'bar',
            name: 'Arsenal',
            stack: 'arsenal', // chave do sub-setor
            data: [0],
            label: {
              show: true,
              position: 'top',
            },
          },
        ],
      },
    };
  },

  watch: {
    colaboradores: {
      deep: true,
      immediate: false,
      async handler(v) {
        this.updateIdUsuarios(v);
        if (this.id_usuarios.length) this.updateChart();
        else this.resetChartData();
      },
    },
    dateRange: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.id_usuarios.length) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.id_usuarios.length) this.updateChart();
      },
    },
  },

  methods: {
    updateIdUsuarios(colaboradores) {
      this.id_usuarios = colaboradores.map((usuario) => usuario.value);
      if (this.id_usuarios.length > this.old_id_usuarios.length) {
        const new_id_usuario = this.id_usuarios.find((id) => !this.old_id_usuarios.includes(id));
        this.old_id_usuarios.push(new_id_usuario);
      } else if (this.id_usuarios.length < this.old_id_usuarios.length) {
        const index_removed_usuario = this.old_id_usuarios.indexOf((id) => !this.id_usuarios.includes(id));
        this.old_id_usuarios.splice(index_removed_usuario, 1);
      }
    },

    async updateChart() {
      const processamentosByColaborador = await this.getChartData();
      this.resetChartData();
      if (processamentosByColaborador) this.updateChartData(processamentosByColaborador);
    },

    async getChartData(id_new_usuario) {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_usuarios: id_new_usuario ? [id_new_usuario] : this.id_usuarios,
      };
      try {
        this.loading = true;

        const processamentosByColaborador = await GenericApi.getWithoutPagination(
          { filters },
          'home/processamentosGroupedByUser',
        );
        return processamentosByColaborador;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à produtividade de colaboradores';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    resetChartData() {
      const numberOfColaboradores = this.colaboradores.length;
      this.chartOptions.series.forEach((serieStack, index) => {
        this.chartOptions.series[index].data = Array(numberOfColaboradores).fill(0);
      });
      this.chartOptions.xAxis[0].data = Array(numberOfColaboradores);
    },

    updateChartData(processamentosByColaborador) {
      this.colaboradores.forEach((colaborador, colaborador_index) => {
        const { value: id_colaborador, label: name_colaborador } = colaborador;
        const processamentoColaborador = processamentosByColaborador
          .find((processamento) => processamento.id_usuario === id_colaborador);
        if (processamentoColaborador) {
          this.chartOptions.series.forEach((serieStack, serie_index) => {
            const { stack: chave_area } = serieStack;
            this.chartOptions
              .series[serie_index]
              .data[colaborador_index] = processamentoColaborador[chave_area] || 0;
          });
        }
        this.chartOptions.xAxis[0].data[colaborador_index] = name_colaborador;
      });
    },
    async exportData() {
      this.loadingExport = true;
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
        id_usuarios: this.id_usuarios,
      };
      const exportedData = await this.getExportedData(
        'home/processamentosGroupedByUser/export',
        filters,
        'Erro ao recuperar dados produtividade de colaboradores para exportação',

      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        const { vouchers_inicio } = processo;
        let voucher_recebimento;
        let voucher_transferencia;
        if (vouchers_inicio) {
          voucher_recebimento = vouchers_inicio.find((voucher) => voucher.tipo === 'recebimento');
          voucher_transferencia = vouchers_inicio.find((voucher) => voucher.tipo === 'transferenciaExpurgo');
        }
        delete processo.vouchers_inicio;
        return {
          ...processo,
          data_processamento,
          Voucher: voucher_recebimento && `R${voucher_recebimento.numero}`,
          // eslint-disable-next-line quote-props
          'Transferência': voucher_transferencia && `T${voucher_transferencia.numero}`,
        };
      });
      this.createXlsx(processosExported, 'Produtividade Colaboradores');
      this.loadingExport = false;
    },
  },

};
</script>

<style>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
.dropdown-menu {
  width: 250%;
}

#button-ok-colaboradores {
  margin-top: 1rem;
  width: 100%;
}

#select-colaboradores {
  display: inline-block;
  margin-right: 5px;
  width: 100%
}

#ok-button-colaboradores {
  display:inline-block
}

.dropdown-menu.inner{
display: block;
}
.center-div{
  position: relative;
}

.center-div-content{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

</style>
