<template>
  <div>
    <b-overlay
      :show="isBusy.chart"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="div-chart">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="8">
                <h3>Disponibilização do material</h3>
              </b-col>
            </b-row>
            <br>
            <b-row >
              <b-col cols="12">
                <h1>{{mediaTotal}}</h1>
              </b-col>
            </b-row>
            <b-row class="div-chart-legend">
              <b-col cols="7">
                <span>Média total das áreas no período</span>
              </b-col>
              <b-col cols="5">
                <RangeDatePicker
                  :initDate="initDate"
                  :endDate="endDate"
                  :isBusy="isBusy.chart"
                  @selected="rangeDateSelected"
                  class="RangeDatePicker-in-graph pr-3"
                />
              </b-col>
            </b-row>
            <ECharts
              @click="onChartClick"
              :option="chart"/>
          </b-col>
        </b-row>
      </div>
    </b-overlay>

    <Modal
      ref="modal"
      :title="modal.title"
      size="xl"
    >
      <b-row>
        <b-col cols="4">Data: {{ modal.data }}</b-col>
        <b-col cols="4">Número de materiais: {{ modal.numeroMateriais }}</b-col>
        <b-col cols="4">Area: {{ modal.setor }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="4">Tempo médio: {{ modal.tempoMedio }}</b-col>
      </b-row>
      <b-table
        :fields="modal.tableFields"
        :items="modal.ciclos"
        class="mt-3"
        responsive
        show-empty
        empty-text="Não existem processos para serem exibidos"
      >
        <template #cell(material)="{ item }">
          <router-link
            v-if="item.id_material"
            :to="{ path: `/material/rastrear/${item.id_material}` }"
            target="_blank"
          >
            {{ item.material }}
          </router-link>
          <label v-else>{{ item.material }}</label>
        </template>
      </b-table>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';
import GenericApi from '../../../services/genericRequest';
import Modal from '../../../components/Utils/Modal';
import CONSTS from '../../../helpers/constants';
import RangeDatePicker from '../../../components/Form/RangeDatePicker';

moment.locale('pt-br');

export default {
  name: 'DisponibilidadeMaterial',
  components: {
    ECharts,
    Modal,
    RangeDatePicker,
  },

  data() {
    return {
      chart: {
        color: ['#1B856F', '#B4D8F0', '#73E6CF'],
        legend: { show: false, data: [] },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: true },
            data: [],
            axisLabel: {
              rotate: 30,
            },
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
      },

      chartOptions: {
        day: '',
      },
      chartData: {},

      modal: {
        title: '',
        data: '',
        numeroMateriais: 0,
        tempoMedio: '',
        tableFields: [{
          key: 'numero',
          label: '#',
        }, {
          key: 'shownId',
          label: 'ID',
        }, {
          key: 'descricao',
          label: 'Descrição',
        }, {
          key: 'date',
          label: 'Data/Hora Inicio',
        }, {
          key: 'date1',
          label: 'Data/Hora Final',
        }, {
          key: 'tempo',
          label: 'Tempo',
        }],
      },

      isBusy: {
        chart: false,
      },

      mediaTotal: '00:00',
      initDate: moment((`${moment().month() + 1}-01-${moment().year()}`)),
      endDate: moment(),
    };
  },
  async mounted() {
    this.fillChartData();
  },

  methods: {
    onChartClick(event) {
      this.modal.title = 'Disponibilização do material';
      this.modal.data = this.chartOptions.day;
      this.modal.numeroMateriais = this.chartData[event.name].length;
      this.modal.setor = event.name;
      this.modal.tempoMedio = event.value ? this.getFormattedSecondsDifference(event.value, 'hours') : '-';

      this.modal.ciclos = this.chartData[event.name].map((material, index) => {
        const endDate = moment(material.data_hora_termino);
        const startDate = moment(material.data_hora_inicio);

        return {
          numero: index + 1,
          date: material.data_hora_inicio ? startDate.format('DD-MM-YYYY HH:mm:ss') : '-',
          date1: material.data_hora_termino ? endDate.format('DD-MM-YYYY HH:mm:ss') : '-',
          shownId: material.shownId,
          descricao: material.descricao,
          tempo: this.getDateDifference(endDate, startDate, 1),
        };
      });

      this.$refs.modal.show();
    },

    getDateDifference(endDate_, startDate_, type = 1) {
      if (endDate_ === '-' || startDate_ === '-') return '-';
      const endDate = moment(endDate_);
      const startDate = moment(startDate_);

      const duration = moment.duration(endDate.diff(startDate));
      if (type === 1) { // Format HH:mm:ss
        let hours = String(duration.days() * 24 + duration.hours());
        if (hours.length === 1) hours = `0${hours}`;
        let minutes = String(duration.minutes());
        if (minutes.length === 1) minutes = `0${minutes}`;
        let seconds = String(duration.seconds());
        if (seconds.length === 1) seconds = `0${seconds}`;
        return `${hours}:${minutes}:${seconds}`;
      }

      if (type === 2) { // Seconds
        return ((duration.days() * 24 + duration.hours()) * 3600) + (duration.minutes() * 60) + duration.seconds();
      }
      return null;
    },

    getFormattedDate(initialDate_, lastDate_) {
      if (!initialDate_ || !lastDate_) return moment();
      const initialDate = moment(initialDate_).format('DD-MM-YYYY');
      const lastDate = moment(lastDate_).format('DD-MM-YYYY');
      if (initialDate !== lastDate) {
        return `${initialDate} até ${lastDate}`;
      }
      return initialDate;
    },

    getFormattedSecondsDifference(seconds, type = null) {
      if (type === 'onlyHours') {
        // eslint-disable-next-line no-nested-ternary
        return Math.floor(seconds / 3600) ? `${Math.floor(seconds / 3600)}h`
          : Math.floor(seconds % 60) ? `${Math.floor(seconds % 60)}m` : '';
      }

      if (type === 'hours') {
        const h = Math.floor(seconds / 3600) ? `${Math.floor(seconds / 3600)}` : 0;
        // eslint-disable-next-line no-nested-ternary
        const m = !Math.floor(seconds / 3600)
          ? Math.floor(seconds / 60)
          : Math.floor(seconds % 60) ? Math.floor(seconds % 60) : 0;

        let hours = `${h}`;
        let minutes = `${m}`;
        if (h < 10) hours = `0${h}`;
        if (m < 10) minutes = `0${m}`;
        return `${hours}:${minutes}h`;
      }

      const h = Math.floor(seconds / 3600) ? `${Math.floor(seconds / 3600)}h` : '';
      const m = Math.floor(seconds % 60);
      return `${h}${m}m`;
    },

    async fillChartData() {
      const initialDate = this.initDate;
      const lastDate = this.endDate;
      this.chartOptions.day = this.getFormattedDate(initialDate, lastDate);

      this.chart = {
        legend: {
          data: [],
          axisTick: { show: true },
        },
        xAxis: [
          {
            axisTick: { show: true },
            data: [],
          },
        ],
        yAxis: [{ type: 'value' }],
        series: [],
        tooltip: {
          show: true,
          trigger: 'item',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          borderColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: '#ffffff',
          },
          formatter: (v) => `
            Média de ${this.getFormattedSecondsDifference(v.value, 'hours')} no ${v.name.toLowerCase()}
          `,
        },
      };

      this.isBusy.chart = true;

      const filters = { initialDate, lastDate };
      const result = await GenericApi.getWithoutPagination(
        { filters },
        'home/getDisponibilizacaoMaterial',
      );

      this.chartData = result.processo.reduce((ss, acc) => ({ ...acc, ...ss }), {});

      this.chart.series = [];
      Object.keys(result).forEach((processo) => {
        this.chart.series.push({
          color: [],
          name: processo,
          type: 'bar',
          data: [],
          label: {
            show: true,
            position: 'top',
            fontWeight: 500,
            fontSize: 22,
            formatter: (v) => this.getFormattedSecondsDifference(v.value, 'hours'),
          },
        });
      });

      this.chart.xAxis[0].data = [];
      const processo = Object.keys(result);
      let setores = [];
      if (Array.isArray(processo) && processo.length > 0) {
        setores = result[processo].reduce((acc, setor) => {
          const [subSetor] = Object.keys(setor);
          return [...acc, subSetor];
        }, []);
      }

      let mediaTotalTempoDisponibilizacao = 0;
      setores.forEach((subSetor, index) => {
        this.chart.xAxis[0].data.push(subSetor);
        this.chart.series[0].color.push(CONSTS.subSetorNameColor[subSetor] || '#AFB4D6');

        const [processoNome] = Object.keys(result);

        let tempoTotalDisponibilizacao = 0;
        let mediaTempoDisponibilizacao = 0;
        let numMateriaisProcessados = 0;

        const dataSetor = result[processoNome][index][subSetor];
        dataSetor.forEach((mp) => {
          if (mp.data_hora_termino && mp.data_hora_inicio) {
            tempoTotalDisponibilizacao += this.getDateDifference(mp.data_hora_termino, mp.data_hora_inicio, 2);
            numMateriaisProcessados++;
          }
        });

        if (tempoTotalDisponibilizacao && numMateriaisProcessados) {
          mediaTempoDisponibilizacao = parseInt(tempoTotalDisponibilizacao / numMateriaisProcessados, 10);
          mediaTotalTempoDisponibilizacao += mediaTempoDisponibilizacao;
        }

        this.chart.series
          .find((series) => series.name === processoNome).data
          .push(mediaTempoDisponibilizacao);
      });

      this.chart.yAxis = [{
        type: 'value',
        axisLabel: {
          formatter: (v) => this.getFormattedSecondsDifference(v, 'hours'),
        },
      }];
      this.isBusy.chart = false;
      this.mediaTotal = this.getFormattedSecondsDifference(
        parseInt(mediaTotalTempoDisponibilizacao / setores.length, 10), 'hours',
      );
    },

    rangeDateSelected(initDate, endDate) {
      this.initDate = initDate;
      this.endDate = endDate;
      this.fillChartData();
    },
  },
};
</script>

<style lang='scss'>
.echarts {
  width: 100%;
  height: 100%;
  min-height: 20rem;
}

.div-chart {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 2rem;
  padding-bottom: 0;
}

.div-chart-legend {
  margin-top: -1rem;
}

.chart-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
}

.invision-input.small {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  height: 27px;
}
.select-options {
  padding: 0px 8px !important;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .display {
    display: block;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  .display {
    display: block;
  }
}
</style>
