<template>
  <div>
    <b-row>
      <b-col cols="7">
        <h4 class="row-dash-title">Produtividade por área</h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row align-h="end">
          <b-col cols="2">
            <GenericSelect
              v-model="id_sub_setor"
              route="subSetor"
              labelKey="nome"
              ref="area"
              :state="null"
              :disabled="loading"
              />
          </b-col>
          <b-col cols="1" class="center-div">
            <div v-if="loadingExport" class="center-div-content">
              <b-spinner class="load-icon"/>
            </div>
            <div @click="exportData" class="center-div-content" v-else>
              <external-link-icon
                style="cursor:pointer"
                size="1.5x"
                v-b-tooltip.hover.right="'Exportar dados'"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import GenericSelect from '../../../components/Form/GenericSelect';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'ProdutividadeArea',
  components: {
    ECharts,
    GenericSelect,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
    dateRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingExport: false,
      mountedFirstTime: false,
      id_sub_setor: null,
      chartOptions: {
        color: ['#FF7D29', '#21CCA9'],
        legend: {
          data: ['Diurno', 'Noturno'],
          left: '10%',
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
            max: (value) => Math.round(value.max * 1.1 + ((value.max * 1.1) % 10)),
          },
        ],
        series: [
          {
            name: 'Diurno',
            type: 'bar',
            stack: 'diurno',
            data: [],
            label: {
              show: true,
              position: 'top',
              rotate: 90,
              verticalAlign: 'middle',
              offset: [10, 0],
            },
            itemStyle: {
              borderRadius: [20, 20, 0, 0],
            },
          },
          {
            type: 'bar',
            name: 'Noturno',
            stack: 'Noturno',
            data: [],
            label: {
              show: true,
              position: 'top',
              rotate: 90,
              verticalAlign: 'middle',
              offset: [10, 0],
            },
            itemStyle: {
              borderRadius: [20, 20, 0, 0],
            },
          },
        ],
      },
    };
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    id_sub_setor() {
      if (this.mountedFirstTime) this.updateChart();
    },
    dateRange: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    async updateChart() {
      const processamentoByShift = await this.getChartData();
      if (processamentoByShift) this.updateChartData(processamentoByShift);
    },

    async getChartData() {
      const area = this.$refs.area.apiResponse.find(
        (item) => item.id_sub_setor === this.id_sub_setor,
      );
      // o turno termina de um dia termina no dia seguinte as 7 horas
      const endShiftDate = this.dateRange.endDate.clone()
        .startOf('day').add(1, 'day').set('hour', 7)
        .subtract(1, 'second');
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: endShiftDate.toISOString(),
        area: this.id_sub_setor ? area.chave : null,
      };
      try {
        this.loading = true;

        const ProcessamentoByShift = await GenericApi.getWithoutPagination(
          { filters },
          'home/qtdProcessamentoGroupedByDayAndShift',
        );
        return ProcessamentoByShift;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os dados relativos à produtividade por área';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      } finally {
        this.loading = false;
      }
    },

    updateChartData(processamentoByShift) {
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];
      processamentoByShift.forEach((processosDia, index) => {
        this.chartOptions.series[0].data[index] = processosDia.processosDiurno;
        this.chartOptions.series[1].data[index] = processosDia.processosNoturno;
      });
      const initDay = this.dateRange.initDate.clone();
      const numberOfDays = processamentoByShift.length;
      const days = Array.from({ length: numberOfDays }, (v, k) => initDay.clone().add(k, 'day').format('DD/MM'));
      this.chartOptions.xAxis[0].data = days;
    },

    async exportData() {
      this.loadingExport = true;
      const area = this.$refs.area.apiResponse.find(
        (item) => item.id_sub_setor === this.id_sub_setor,
      );
      // o turno termina de um dia termina no dia seguinte as 7 horas
      const endShiftDate = this.dateRange.endDate.clone()
        .startOf('day').add(1, 'day').set('hour', 7)
        .subtract(1, 'second');
      const initShiftDate = this.dateRange.initDate.clone().startOf('day').set('hour', 7);
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: initShiftDate.toISOString(),
        endDate: endShiftDate.toISOString(),
        area: this.id_sub_setor ? area.chave : null,
      };
      const exportedData = await this.getExportedData(
        'home/qtdProcessamentoGroupedByDayAndShift/export',
        filters,
        'Erro ao recuperar dados produtividade de Área para exportação',
      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        const { vouchers_inicio } = processo;
        let voucher_recebimento;
        let voucher_transferencia;
        if (vouchers_inicio) {
          voucher_recebimento = vouchers_inicio.find((voucher) => voucher.tipo === 'recebimento');
          voucher_transferencia = vouchers_inicio.find((voucher) => voucher.tipo === 'transferenciaExpurgo');
        }
        processo.evenOrOddDay = processo.evenOrOddDay === 'odd' ? 'ímpar' : 'par';
        processo.Turno = `${processo.Turno} - ${processo.evenOrOddDay}`;
        delete processo.evenOrOddDay;
        delete processo.vouchers_inicio;
        delete processo.formulario;
        return {
          ...processo,
          data_processamento,
          Voucher: voucher_recebimento && `R${voucher_recebimento.numero}`,
          // eslint-disable-next-line quote-props
          'Transferência': voucher_transferencia ? `T${voucher_transferencia.numero}` : '-',
        };
      });
      this.createXlsx(processosExported, 'Processamentos');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
</style>
