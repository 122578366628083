<template>
  <div>
    <b-row>
      <b-col cols="9">
        <h4 class="row-dash-title">Tempo médio de processamento por tipo de material</h4>
      </b-col>
      <b-col cols="2">
        <RangeDatePicker
          :initDate="dateRange.initDate"
          :endDate="dateRange.endDate"
          :state="null"
          :isBusy="loading"
          @selected="selectedDateInDatePicker"
        />
      </b-col>
      <b-col cols="1">
        <div v-if="loadingExport || loading" class="mr-0">
          <b-spinner class="load-icon"/>
        </div>
        <div @click="exportData" v-else>
          <external-link-icon
            style="cursor:pointer"
            size="1.5x"
            class="mr-0"
            v-b-tooltip.hover.right="'Exportar dados'"
          />
        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      class="dashboard-overlay"
    >
      <div class="dash-box">
        <b-row>
          <b-col cols="12">
            <ECharts :option="chartOptions" />
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import ECharts from 'vue-echarts';

import RangeDatePicker from '../../../components/Form/RangeDatePicker';
import GenericApi from '../../../services/genericRequest';

export default {
  name: 'CicloIncompleto',
  components: {
    ECharts,
    RangeDatePicker,
  },
  inject: ['getExportedData', 'createXlsx'],
  props: {
    unidadeIndicadores: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dateRange: {
        initDate: moment().subtract(7, 'days').startOf('day'),
        endDate: moment().endOf('day'),
      },
      loading: false,
      loadingExport: false,
      mountedFirstTime: false,
      tempoChavesInOrder: [
        'tempo_transporte_central', 'tempo_processamento', 'tempo_transporte_geral', 'tempo_arsenal',
      ],
      chartOptions: {
        color: ['#73E5CF', '#FF7D29', '#3989BF', '#FF3348'],
        legend: {
          left: '10%',
          bottom: 10,
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            const tooltip = params.reduce((htmlTooltip, unidade) => {
              const valueHtml = `${htmlTooltip} <br> ${unidade.marker} ${unidade.seriesName} &nbsp &nbsp
                <span style="float:right">${this.minutesToHourMinutes(unidade.value)}</span>`;
              return valueHtml;
            }, `${params[0].name} `);
            return tooltip;
          },
        },
        xAxis: [
          {
            show: true,
            type: 'category',
            axisTick: { show: false },
            data: [],
            axisLabel: {
              interval: 0,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
            axisLabel: {
              formatter: (v) => this.minutesToHourMinutes(v),
            },
          },
        ],
        series: [
          {
            name: 'Transporte para unidade central',
            type: 'bar',
            stack: 'principal',
            data: [],
          },
          {
            name: 'Processamento',
            type: 'bar',
            stack: 'principal',
            data: [],
          },
          {
            name: 'Transporte para unidade não central',
            type: 'bar',
            stack: 'principal',
            data: [],
          },
          {
            name: 'Arsenal',
            type: 'bar',
            stack: 'principal',
            data: [],
          },
        ],
      },
    };
  },

  computed: {
    idsTipoMaterial() {
      if (this.id_tipo_material) return [this.id_tipo_material];
      if (!this.$refs.tipoMaterial) return [];
      const ids = this.$refs.tipoMaterial.options.map((item) => item.value);
      return ids;
    },
  },

  mounted() {
    this.updateChart();
    this.mountedFirstTime = true;
  },

  watch: {
    idsTipoMaterial() {
      if (this.mountedFirstTime) this.updateChart();
    },
    dateRange: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
    unidadeIndicadores: {
      deep: true,
      async handler() {
        if (this.mountedFirstTime) this.updateChart();
      },
    },
  },

  methods: {
    selectedDateInDatePicker(initDate, endDate) {
      this.dateRange.initDate = initDate;
      this.dateRange.endDate = endDate;
    },

    minutesToHourMinutes(min) {
      if (!min) return '0:00';
      const hours = Math.floor(min / 60);
      const minutes = Math.floor(min % 60);
      const minutesString = `${minutes}`.padStart(2, '0');
      return `${hours}:${minutesString}`;
    },

    async updateChart() {
      this.loading = true;

      const tempoProcessamento = await this.getChartData();
      if (tempoProcessamento) this.updateChartData(tempoProcessamento);

      this.loading = false;
    },

    async getChartData() {
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
      };
      try {
        const tempoProcessamento = await GenericApi.getWithoutPagination(
          { filters },
          'home/tempoProcessamento',
        );

        return tempoProcessamento;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar os tempos de processamento dos materiais';
        if (
          error.response
            && error.response.data
            && error.response.data.error
            && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });
        return false;
      }
    },

    updateChartData(tempoProcessamento) {
      // const numeroMateriais = Object.keys(tempoProcessamento).length;
      // eslint-disable-next-line max-len
      const tiposMateriais = Object.keys(tempoProcessamento);
      this.chartOptions.xAxis[0].data = tiposMateriais;
      const mediaTempoProcessamentoByMaterial = tiposMateriais.reduce((result, tipoMaterialKey) => {
        const temposTipoMaterial = tempoProcessamento[tipoMaterialKey];
        const emptyArrayOfTempos = this.createArrayOfTempos();
        const totalTempoMaterial = temposTipoMaterial.reduce((temposTotais, ciclo) => {
          if (ciclo.tempo_transporte_central > 0) {
            temposTotais[0].tempo_total += ciclo.tempo_transporte_central;
            temposTotais[0].numero_ciclos_positivo += 1;
          }
          if (ciclo.tempo_processamento > 0) {
            temposTotais[1].tempo_total += ciclo.tempo_processamento;
            temposTotais[1].numero_ciclos_positivo += 1;
          }
          if (ciclo.tempo_transporte_geral > 0) {
            temposTotais[2].tempo_total += ciclo.tempo_transporte_geral;
            temposTotais[2].numero_ciclos_positivo += 1;
          }
          if (ciclo.tempo_arsenal > 0) {
            temposTotais[3].tempo_total += ciclo.tempo_arsenal;
            temposTotais[3].numero_ciclos_positivo += 1;
          }
          return temposTotais;
        }, emptyArrayOfTempos);
        const mediasTempo = this.getMeanFromEveryTempoKey(totalTempoMaterial);
        mediasTempo.forEach((media, index) => {
          result[index].push(media);
        });
        return result;
      }, Array.from({ length: 4 }, () => []));

      mediaTempoProcessamentoByMaterial.forEach((mediaTempo, index) => {
        this.chartOptions.series[index].data = mediaTempo;
      });
    },
    createArrayOfTempos() {
      return this.tempoChavesInOrder.map((chave) => {
        const temposObject = {
          tempo_total: 0,
          numero_ciclos_positivo: 0,
          chave,
        };
        return temposObject;
      });
    },

    getMeanFromEveryTempoKey(temposTotais) {
      return temposTotais.map((tempoTotalMaterial) => {
        const { tempo_total, numero_ciclos_positivo } = tempoTotalMaterial;
        return numero_ciclos_positivo > 0
          ? Math.floor(tempo_total / numero_ciclos_positivo)
          : 0;
      });
    },
    async exportData() {
      this.loadingExport = true;
      const filters = {
        id_unidade: this.unidadeIndicadores,
        initDate: this.dateRange.initDate.toISOString(),
        endDate: this.dateRange.endDate.toISOString(),
      };
      const exportedData = await this.getExportedData(
        'home/tempoProcessamento/export',
        filters,
        'Erro ao recuperar dados de processamentos e transporte',

      );
      if (!exportedData) {
        this.loadingExport = false;
        return;
      }
      const processosExported = exportedData.map((processo) => {
        const data_processamento = moment(processo.data_processamento).format('DD/MM/YYYY HH:mm:ss');
        return {
          ...processo,
          data_processamento,
        };
      });
      this.createXlsx(processosExported, 'Processos e transportes');
      this.loadingExport = false;
    },
  },
};
</script>

<style scoped>
.meta-box {
  width: 45%;
  font-size: 2.2rem;
  border: 1px solid #cfd1e5;
  border-radius: 10px;
  padding: 12px;
  font-weight: 500;
}
.info-text {
  color: rgba(126, 130, 159, 1);
  font-weight: normal;
  font-size: 0.7rem;
}

.div-export {
  background-color: white;
}

.result-meta {
  font-size: 0.7rem;
  position: absolute;
  top: 2.9rem;
  margin-left: 10px;
}
.result-meta.negativo {
  color: red;
}
.result-meta.positivo {
  color: green;
}
</style>
